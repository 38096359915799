import React from 'react';
import styled from 'styled-components';
import {Divider} from '../styles/cssComponents';
import {Office} from "../services/model";
import {
    ContactAddress,
    ContactDetails,
    ContactEmail,
    ContactFax,
    ContactName,
    ContactPhone,
    ContactWww
} from "./ContactDetails";

interface ContactListArgs {
    offices: Office[];
    width?: string;
}

const ListWrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  width: 80%;
  margin: 0 auto;
`;

const StyledDivider = styled(Divider)`
  margin-top: 2rem;
`;

const Header = styled.h2`
  margin: 1rem auto;
  line-height: 150%;
  text-align: center;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  width: ${({width}) => width};
  margin: 0 auto;
`;

const ContactList = ({offices, width = '50%'}: ContactListArgs) => (
    <ListWrapper>
        {offices.map((office, i, array) => (
            <div key={office.id}>
                <>
                    <Header dangerouslySetInnerHTML={{__html: office.title.rendered}}/>
                    <DetailsWrapper width={width}>
                        <ContactDetails title={office.acf.name}>
                            <ContactAddress text={office.acf.address}/>
                            <ContactName text={office.acf.assistant}/>
                            <ContactPhone text={office.acf.phone}/>
                            <ContactFax text={office.acf.fax}/>
                            <ContactEmail text={office.acf.email}/>
                            <ContactWww text={office.acf.www}/>
                        </ContactDetails>
                    </DetailsWrapper>
                </>

                {i !== array.length - 1 && <StyledDivider/>}
            </div>
        ))}
    </ListWrapper>
);

export default ContactList;
