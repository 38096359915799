import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { desktopOnly, mobileOnly } from '../styles/mixins';

const MultimediaListWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  max-width: 1100px;
  margin: 0 auto;
`;

const MultimediaCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  padding: 0 50px;
  
  &:nth-child(n + 2) {
    border-top: 1px solid #D3D3D3;
    padding-top: 2rem;
  }
  
  ${down('md')} {
    padding: 0;
    grid-template-columns: auto;
    justify-items: center;
  }
`;

const VideoDesktop = styled.div`
  ${desktopOnly()}
  grid-row: 1/-1;
`;

const VideoMobile = styled.div`
  ${mobileOnly()}
`;

const StyledMobileIframe = styled.iframe`
  width: 90vw;
  height: 45vw;
  max-width: 100%;
`;

export const MultimediaList = ({children}) => (
    <MultimediaListWrapper>
        {children}
    </MultimediaListWrapper>
);

export const MultimediaCard = ({video, title, description}) => {

    const videoId = video.split('?v=')[1].split('&')[0];

    return (
        <MultimediaCardWrapper>
            <VideoDesktop>
                <iframe width="510" height="340"
                        src={`https://www.youtube.com/embed/${videoId}`}>
                </iframe>
            </VideoDesktop>

            <VideoMobile>
                <StyledMobileIframe src={`https://www.youtube.com/embed/${videoId}`} />
            </VideoMobile>

            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{__html: description}}/>
        </MultimediaCardWrapper>
    );
};