import {PageHeader} from "../../styles/cssComponents";
import React, {useEffect, useState} from "react";
import {getRdpp} from "../../services/apiService";
import {centeredSection} from "../../styles/mixins";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import rdppIds from "../../services/voivodeships"
import {Rdpp} from "../../services/model";
import {
    ContactAddress,
    ContactDetails,
    ContactEmail,
    ContactFacebook,
    ContactPhone,
    ContactWww
} from "../../components/ContactDetails";
import {up} from "styled-breakpoints";
import PageImage from "components/PageImage";

const Wrapper = styled.div`
  ${centeredSection()};
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  margin: 0 auto;

  ${up('md')} {
    max-width: 40rem;
  }
`;

const Content = styled.div`
  > pre {
    white-space: pre-wrap;
  }
`;

const RdppPage = () => {
    const {name} = useParams();
    const [rdpp, setRdpp] = useState<Rdpp>(null);

    useEffect(() => {
        getRdpp(rdppIds[name]).then(data => {
            setRdpp(data);
        })
    }, [name]);

    return (
        <>
            <PageImage url='/assets/rdpp.jpeg'/>
            {rdpp && (
                <Wrapper>
                    <PageHeader dangerouslySetInnerHTML={{__html: rdpp.title.rendered}}/>
                    <InnerWrapper>
                        <ContactDetails title={rdpp.acf.name}>
                            <ContactAddress text={rdpp.acf.address}/>
                            <ContactEmail text={rdpp.acf.email}/>
                            <ContactPhone text={rdpp.acf.phone}/>
                            <ContactWww text={rdpp.acf.www}/>
                            <ContactFacebook text={rdpp.acf.facebook}/>
                        </ContactDetails>
                        <Content dangerouslySetInnerHTML={{__html: rdpp.acf.content}}/>
                    </InnerWrapper>
                </Wrapper>
            )}
        </>
    );
}

export default RdppPage;
