export const defaultColors = {
    background: 'white',
    foreground: 'black',
    inverseForeground: 'white',
    footerBackground: 'white',
    border: 'black',
    grey: '#E5E5E5',
    blue: '#0075C4',
    green1: '#8CC63E',
    green2: '#4B8500',
    green3: '#83FF00',
    green4: '#105016',
    green5: '#8CC640',
    green6: '#5DA300',
};

export const extraContrastColors = {
    background: 'black',
    foreground: 'white',
    inverseForeground: 'black',
    footerBackground: '#dcdcdc',
    border: '#a0ee00',
    grey: '#2a2a2a',
    blue: 'white',
    green1: 'white',
    green2: '#a9ff00',
    green3: '#105016',
    green4: 'black',
    green5: '#a0ee00',
    green6: '#a0ee00',
};

export const defaultLetterSpacing = 'normal';
export const defaultFontSize = '16px';
export const defaultLineHeight = '1.5';
export const extraLetterSpacing = '1px';
export const extraFontSize = '20px';
export const extraLineHeight = '2';

const theme = {
    fontSize: defaultFontSize,
    letterSpacing: defaultLetterSpacing,
    lineHeight: defaultLineHeight,
    pageImageHeight: '350px',
    mobileImageHeight: '180px',
    contrast: false,
    colors: defaultColors,
    toggleExtraLetterSpacing: function () {
        this.letterSpacing = this.letterSpacing === defaultLetterSpacing ? extraLetterSpacing : defaultLetterSpacing;
    },
    toggleExtraFontSize: function () {
        this.fontSize = this.fontSize === defaultFontSize ? extraFontSize : defaultFontSize;
    },
    toggleExtraLineHeight: function () {
        this.lineHeight = this.lineHeight === defaultLineHeight ? extraLineHeight : defaultLineHeight;
    },
    toggleExtraContrast: function () {
        this.contrast = !this.contrast;
        this.colors = this.contrast ? extraContrastColors : defaultColors;
    },
};

export default theme;