import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader, Divider } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { getPresidiumMembers } from "../../services/apiService";
import { down, up } from 'styled-breakpoints';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const List = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  width: 80%;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
  grid-gap: 2rem;
  
  ${down('md')} {
    grid-template-columns: auto;
    grid-gap: 1.5rem;
  }
`;

const StyledImg = styled.img`
  border-radius: 2rem;
  grid-row: 1/-1;
  
  ${down('md')} {
    max-width: 100%;
  }
  
  ${up('md')} {
    height: 300px;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  
  ${down('md')} {
    font-size: 1.5rem;
  }
`;

const PrezydiumPage = () => {
    const [presidiumMembers, setPresidiumMembers] = useState([]);

    useEffect(() => {
        getPresidiumMembers().then(data => {
            setPresidiumMembers(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/prezydium.jpeg'/>
            <Wrapper>
                <PageHeader>Prezydium</PageHeader>
                <List>
                    {presidiumMembers.map((member, i, arr) => (
                        <>
                            <Grid key={member.id}>
                                <StyledImg src={member.acf.image} alt="zdjecie"/>
                                <Title>{member.title.rendered}</Title>
                                <p dangerouslySetInnerHTML={{__html: member.acf.description}}/>
                            </Grid>

                            {i !== arr.length - 1 && <Divider/>}
                        </>
                    ))}
                </List>
            </Wrapper>
        </>
    );
};

export default PrezydiumPage;