import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  padding: 10px 20px;
  font-size: 16px;
  width: 100%;
  ${({inputStyle}) => inputStyle};
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: ${({fontSize}) => Math.floor(fontSize / 2) - 1}px;
  top: 50%;
  bottom: 50%;
  transform: translate(0, -50%);
  color: ${({iconColor}) => iconColor};
`;

const IconInput = ({
                     icon,
                     iconColor = 'black',
                     placeholder = '',
                     onIconClick = null,
                     onInput = null,
                     iconSize = 16,
                     emitClickOnEnter = false,
                     style = null,
                     value = null,
                   }) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter' && emitClickOnEnter) {
      onIconClick();
    }
  };

  return (
    <InputWrapper>
      <Input inputStyle={style} type="text" placeholder={placeholder} onInput={onInput} onKeyDown={handleKeyDown}
             value={value}/>
      <Icon fontSize={iconSize} icon={icon} onClick={onIconClick} cursor="pointer" iconColor={iconColor}/>
    </InputWrapper>
  );
};

export default IconInput;