import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { MultimediaCard, MultimediaList } from '../../components/Multimedia';
import { Multimedia } from '../../services/model';
import { getPodcasts } from '../../services/apiService';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const PodcastyPage = () => {
    const [docs, setDocs] = useState<Multimedia[]>([]);

    useEffect(() => {
        getPodcasts().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/podcasty.jpeg'/>
            <Wrapper>
                <PageHeader>Podcasty</PageHeader>
                <MultimediaList>
                    {docs.map(doc => <MultimediaCard key={doc.id} title={doc.title.rendered} description={doc.acf.description} video={doc.acf.url}/>)}
                </MultimediaList>
            </Wrapper>
        </>
    );
};

export default PodcastyPage;