import { ContactDetails, ContactAddress, ContactPhone, ContactEmail } from 'components/ContactDetails';
import PageImage from 'components/PageImage';
import rdpp from 'pages/rdpp';
import React, { useEffect, useState } from 'react';
import { getOfficeContact } from 'services/apiService';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const Office = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  margin-top: 2rem;
  max-width: 30rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  
  ${down('md')} {
    max-width: 100%;
  }
  
  ${up('md')} {
    height: 300px;
  }
`;

const BiuroPage = () => {
  const [contact, setContact] = useState(null);

  useEffect(() => {
    getOfficeContact().then(data => {
      setContact(data);
    })
  }, []);

  return (
    <>
      <PageImage url='/assets/biuro.jpeg'/>
      <Wrapper>
        <PageHeader>Biuro / kontakt</PageHeader>

        {contact &&
          <InnerWrapper>
            <StyledImg src={contact.acf.image.url} alt="mapa" />
            <Office>
              <ContactDetails title="BIURO KONWENTU WRDPP">
                <ContactAddress text={contact.acf.address} />
                <ContactPhone text={contact.acf.phone} />
                <ContactEmail text={contact.acf.email} />
              </ContactDetails>
            </Office>
          </InnerWrapper>
        }
      </Wrapper>
    </>
  );
};

export default BiuroPage;