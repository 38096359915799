import React from 'react';
import styled from 'styled-components';
import PageImage from 'components/PageImage';
import {CenteredText, PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import {down} from "styled-breakpoints";

const Wrapper = styled.div`
  ${centeredSection()};
  width: 80%;
  margin: 0 auto;
  text-align: justify;

  ${down('md')} {
    width: 100%;
  }
`;

const CenteredBoldText = styled(CenteredText)`
  font-weight: bold;
`;

const ContentSection = styled.p`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const InformacjaOPWBPage = () => (
    <>
        <PageImage url='/assets/publikacje-w-biurze.jpeg'/>

        <Wrapper>
            <PageHeader>Informacja o publikacjach w biurze</PageHeader>
            <CenteredBoldText>System LEX</CenteredBoldText>
            <ContentSection>
                W biurze Konwentu WRDPP przy ul. Raszyńskiej 3A, lok. 4, 02-026 Warszawa można skorzystać z
                platformy internetowej LEX. Biuro pracuje w godzinach 8:00-16:00.
            </ContentSection>

            <ContentSection>
                System LEX umożliwia w prosty sposób wyszukiwanie informacji: haseł, aktów prawnych, orzeczeń,
                komentarzy, procedur, wzorów oraz innych dokumentów.
            </ContentSection>

            <ContentSection>
                Dzięki powiązaniami pomiędzy poszczególnymi kategoriami dokumentów w systemie oraz możliwości
                precyzyjnego dotarcia do poszukiwanych zasobów, w tym zawężaniu wyników platforma LEX może
                przyśpieszyć i ułatwić pracę członkom Rad Działalności Pożytku Publicznego.
            </ContentSection>
        </Wrapper>
    </>
);

export default InformacjaOPWBPage;