import { useContext } from 'react';
import ThemeContext from './ThemeContext';

type cases = 'contrast' | 'letterSpacing' | 'fontSize' | 'lineHeight';

const useTheme = () => {
    const { theme, updateTheme } = useContext(ThemeContext);

    const update = (caseUpdate: cases) => {
        switch (caseUpdate) {
            case 'letterSpacing': {
                theme.toggleExtraLetterSpacing();
                break;
            }
            case 'fontSize': {
                theme.toggleExtraFontSize();
                break;
            }
            case 'lineHeight': {
                theme.toggleExtraLineHeight();
                break;
            }
            case 'contrast': {
                theme.toggleExtraContrast();
                break;
            }
        }

        updateTheme({...theme});
    };

    return {theme, update}
}

export default useTheme;