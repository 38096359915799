import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CenteredText, PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import DocList from '../../components/DocList';
import { getOpinionsAndExpertises } from '../../services/apiService';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const OpiniePage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getOpinionsAndExpertises().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/opinie-ekspertyzy.jpeg'/>
            <Wrapper>
                <PageHeader>Opinie / ekspertyzy</PageHeader>
                <CenteredText>Opinie oraz ekspertyzy w obszarze pożytku publicznego dotyczące współpracy administracji
                    publicznej i organizacji pozarządowych.</CenteredText>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
};

export default OpiniePage;