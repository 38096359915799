import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { getOurProjects } from '../../services/apiService';
import DocList from '../../components/DocList';
import PageImage from 'components/PageImage';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const ProjektyPage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getOurProjects().then(data => {
            setDocs(data);
        })
    }, []);

    return (
        <>
            <PageImage url='/assets/nasze-projekty.jpeg'/>
            <Wrapper>
                <PageHeader>Nasze projekty</PageHeader>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
}

export default ProjektyPage;