import {News} from "./model";

export function getUrlTitle(news: News) {
    const title = news.title.rendered?.toLowerCase()
        .replace(/ł/gm, 'l')
        .normalize('NFKD')
        .replace(/[^\w\s]/gm, '')
        .replace(/\s/gm, '-');

    return `${news.id}-${title}`;
}

export function getIdFromUrlTitle(urlTitle: string) {
    return urlTitle?.split('-')[0] ?? [];
}
