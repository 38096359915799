import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import DocList from '../../components/DocList';
import { getPublicTaskRecomendations } from '../../services/apiService';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const RekomendacjeZPPage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getPublicTaskRecomendations().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/rekomendacjeZP.jpeg'/>

            <Wrapper>
                <PageHeader>Rekomendacje zadań publicznych</PageHeader>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
};

export default RekomendacjeZPPage;