import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import styled from "styled-components";
import {getMapLocations} from '../../services/apiService';
import {centeredSection} from "../../styles/mixins";
import { CenteredText, PageHeader, SecondaryHeader } from "../../styles/cssComponents";
import {DivIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import PageImage from 'components/PageImage';
import { down } from 'styled-breakpoints';

const Map = styled(MapContainer)`
  display: grid;
  grid-row-gap: 2rem;
  margin: 3rem auto;
  width: 80%;
  height: 600px;
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: 1rem;
`;

const ParsedPopup = styled(Popup)`
  white-space: pre;
`;

const Wrapper = styled.div`
  ${centeredSection()};
`;

const RegionDetailButtonsGrid = styled.div`
  margin: 0 auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 36px;

  ${down('xl')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const BaseDetailsLink = styled(Link)`
  border-radius: 20px;
  border: none;
  height: 80px;
  padding: 8px 30px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  color: ${({theme}) => theme.colors.inverseForeground};
`;

const VoivodeshipButton = styled(BaseDetailsLink)`
  background: ${({theme}) => theme.colors.green2};

  :nth-child(2n):not(:hover) {
    background: ${({theme}) => theme.colors.green5};
  }

  :hover {
    background: ${({theme}) => theme.colors.blue};
  }
`;

const GeneralButton = styled(BaseDetailsLink)`
  margin-top: 26px;
  background: ${({theme}) => theme.colors.blue};
  grid-column: span 2;

  ${down('sm')} {
    grid-column: 1;
  }

  :hover {
    background: ${({theme}) => theme.colors.green6};
  }
`;

interface MapPoint {
    id: number;
    lat: number;
    lng: number;
    description: string;
    color: string;
}

const FontAwesomeMarker = (mapPoint: MapPoint) => {
    const iconConfig = new DivIcon({
        html: renderToStaticMarkup(<FontAwesomeIcon color={mapPoint.color} icon={faLocationDot}/>),
        iconSize: [32, 32]
    });

    return (
        <Marker position={[mapPoint.lat, mapPoint.lng]} icon={iconConfig}>
            <ParsedPopup>{mapPoint.description}</ParsedPopup>
        </Marker>
    );
}

const MapaTeleadresowaPage = () => {
    const [mapPoints, setMapPoints] = useState<MapPoint[]>([])

    useEffect(() => {
        getMapLocations().then(data => {
            setMapPoints(data.map(dat => ({
                id: dat.id,
                lat: dat.acf.map_coords?.lat ?? dat.acf.manual_coords?.lat,
                lng: dat.acf.map_coords?.lng ?? dat.acf.manual_coords?.lng,
                description: dat.acf.description,
                color: dat.acf.color,
            })));
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/mapa-teleadresowa.jpeg'/>
            <Wrapper>
                <PageHeader>Mapa teleadresowa</PageHeader>
                <CenteredText>
                    Mapa teleadresowa zawierająca dane wojewódzkich RDPP, ale także isostnych dla ciał dialogu urzędów
                    centralnych, samorządów wojewódzkich, pełnomocników wojewodów ds. społeczeństwa obywatelskiego oraz
                    najwiekszych federacji organizacji pozarządowych.
                </CenteredText>
                <Map center={[52.1874047, 19.9182129]} zoom={6}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {mapPoints.map(mapPoint => (
                        <FontAwesomeMarker key={mapPoint.id} {...mapPoint}/>
                    ))}
                </Map>
                <SecondaryHeader>Wojewódzkie RDPP</SecondaryHeader>
                <RegionDetailButtonsGrid>
                    <VoivodeshipButton to={'/rdpp/dolnoslaskie'} state={'d'}>DOLNOŚLĄSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/kujawsko-pomorskie'}>KUJAWSKO - POMORSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/lubelskie'}>LUBELSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/lubuskie'}>LUBUSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/lodzkie'}>ŁÓDZKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/malopolskie'}>MAŁOPOLSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/mazowieckie'}>MAZOWIECKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/opolskie'}>OPOLSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/podkarpackie'}>PODKARPACKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/podlaskie'}>PODLASKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/pomorskie'}>POMORSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/slaskie'}>ŚLĄSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/swietokrzyskie'}>ŚWIĘTOKRZYSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/warminsko-mazurskie'}>WARMIŃSKO - MAZURSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/wielkopolskie'}>WIELKOPOLSKIE</VoivodeshipButton>
                    <VoivodeshipButton to={'/rdpp/zachodnio-pomorskie'}>ZACHODNIO - POMORSKIE</VoivodeshipButton>
                    <GeneralButton to={'/urzedy-marszalkowskie'}>URZĘDY MARSZAŁKOWSKIE</GeneralButton>
                    <GeneralButton to={'/pelnomocnicy-wojewodow'}>PEŁNOMOCNICY WOJEWODÓW</GeneralButton>
                </RegionDetailButtonsGrid>
            </Wrapper>
        </>
    );
};

export default MapaTeleadresowaPage;