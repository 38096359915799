import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { getGoodPractices } from '../../services/apiService';
import DocList from '../../components/DocList';
import PageImage from 'components/PageImage';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const DobrePraktykiPage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getGoodPractices().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/dobre-praktyki.jpeg'/>
            <Wrapper>
                <PageHeader>Dobre praktyki</PageHeader>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
}

export default DobrePraktykiPage;