import PageImage from 'components/PageImage';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import {Representative} from "../../services/model";
import {getRepresentatives} from "../../services/apiService";
import Voivodeships, {VoivodeshipTranslations} from "../../services/voivodeships";
import {ContactAddress, ContactEmail, ContactName, ContactPhone, ContactWww} from "../../components/ContactDetails";
import {up} from "styled-breakpoints";

const Wrapper = styled.div`
  ${centeredSection()};
`;

const Header = styled.h2`
  margin: 1rem auto;
  line-height: 150%;
  text-align: center;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;

  ${up('md')} {
    max-width: 40rem;
  }
`;

const ContactWrapper = styled.div`
    margin-bottom: 2rem;
  
  > div {
    margin-bottom: 1rem;
  }
`;

const PrzedstawicielePage = () => {
    const [representatives, setRepresentatives] = useState<{ [id: number]: Representative[] }>(null);
    const [voivodeships] = useState(Object.values(Voivodeships));

    useEffect(() => {
        getRepresentatives().then(data => {
            const representativesMap = {};
            data.forEach(representative => {
                const voivodeshipId = Number(representative.acf.voivodeship.value);
                if (!representativesMap[voivodeshipId]) {
                    representativesMap[voivodeshipId] = [];
                }
                representativesMap[voivodeshipId].push(representative);
            });
            setRepresentatives(representativesMap);
        })
    }, []);

    return (
        <>
            <PageImage url='/assets/przedstawiciele.jpeg'/>
            <Wrapper>
                <PageHeader>Przedstawiciele</PageHeader>
                <InnerWrapper>
                    {voivodeships && representatives && voivodeships.map(id =>
                        <>
                            {representatives[id]?.length > 0 &&
                                <>
                                    <Header>{VoivodeshipTranslations[id]}</Header>
                                    {representatives[id].map(representative =>
                                        <ContactWrapper>
                                            <ContactName text={representative.title.rendered}/>
                                            <ContactEmail text={representative.acf.email}/>
                                            <ContactPhone text={representative.acf.phone}/>
                                            <ContactAddress text={representative.acf.address}/>
                                            <ContactWww text={representative.acf.www}/>
                                        </ContactWrapper>
                                    )}
                                </>
                            }
                        </>
                    )}
                </InnerWrapper>
            </Wrapper>
        </>
    );
}

export default PrzedstawicielePage;