import PageImage from 'components/PageImage';
import React from 'react';
import styled from 'styled-components';
import {PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const Content = styled.div`
  padding: 20px;
  width: 80%;
  margin: 0 auto;
`;

const HighlightedContent = styled.div`
  color: white;
  background-color: #0075C4;
  border-radius: 15px;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
`;

const Header = styled.h3`
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`;

const StyledOrderedList = styled.ol`
  padding-left: 2rem;
`;

const StyledUnorderedList = styled.ul`
  padding-left: 2rem;
`;

const Section = styled.section`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const CookiesPage = () => (
    <>
        <PageImage url='/assets/polityka-cookies.jpeg'/>
        <Wrapper>
            <PageHeader>Polityka prywatności i cookies</PageHeader>
            <Content>
                <Section>
                    <Header>I. Wprowadzenie</Header>
                    <StyledOrderedList>
                        <li>
                            Szanując prywatność użytkowników odwiedzających wydawane przez nas witryny, Konwent
                            Wojewódzkich Rad Działalności Pożytku Publicznego stosuje niniejszą Politykę prywatności,
                            aby zapewnić należytą ochronę danych osobowych użytkowników.
                        </li>
                        <li>
                            Niniejsza Polityka prywatności dotyczy danych osobowych, które mogą być przez nas
                            przetwarzane do celów związanych z funkcjonowaniem witryn Konwentu Wojewódzkich Rad
                            Działalności Pożytku Publicznego oraz Stowarzyszenia „EKOSKOP”, publikowanych w domenach
                            *konwentwrdpp.pl, .ekoskop.pl oraz *.ekoskop.rzeszow.pl.
                        </li>
                        <li>
                            W szczególności Polityka prywatności dotyczy następujących witryn:
                            <StyledUnorderedList>
                                <li>
                                    Publikowanej pod adresem <a href="https://www.ekoskop.pl"
                                                                target="_blank" rel="noreferrer">www.ekoskop.pl</a>, <a
                                    href="http://www.ekoskop.rzeszow.pl" target="_blank"
                                    rel="noreferrer">www.ekoskop.rzeszow.pl</a>
                                </li>
                                <li>
                                    Publikowanej w subdomenie <a
                                    href="http://alarmdlabio.ekoskop.pl"
                                    target="_blank" rel="noreferrer">http://alarmdlabio.ekoskop.pl</a>, <a
                                    href="http://alarmdlabio.ekoskop.rzeszow.pl"
                                    target="_blank" rel="noreferrer">http://alarmdlabio.ekoskop.rzeszow.pl</a>,
                                </li>
                                <li>
                                    Publikowanej w subdomenie <a
                                    href="http://bip.ekoskop.pl" target="_blank"
                                    rel="noreferrer">http://bip.ekoskop.pl</a>, <a
                                    href="http://bip.ekoskop.rzeszow.pl"
                                    target="_blank" rel="noreferrer">http://bip.ekoskop.rzeszow.pl</a>
                                </li>
                                <li>
                                    Strony <a href="https://konwentwrdpp.pl/">www.konwentwrdpp.pl</a>
                                </li>
                            </StyledUnorderedList>
                        </li>
                    </StyledOrderedList>
                </Section>

                <Section>
                    <Header>II. Zbieranie danych</Header>
                    <StyledOrderedList>
                        <li>
                            Podczas wizyt na stronach naszych witryn internetowych zbierane są dane o odwiedzających
                        </li>
                        <li>
                            Dane gromadzone automatycznie i zapisywane w dziennikach serwera (tzw. logach), zawierają
                            szereg informacji o użytkownikach odwiedzających nasze witryny. Znane nam są:
                            <StyledUnorderedList>
                                <li>
                                    zapytania HTTP kierowane do naszego serwera,
                                </li>
                                <li>
                                    publiczne adresy IP, z których użytkownicy przeglądają nasze zasoby,
                                </li>
                                <li>
                                    adresy URL zasobów przeglądanych w naszych witrynach,
                                </li>
                                <li>
                                    czasy nadejścia zapytania i wysłania odpowiedzi,
                                </li>
                                <li>
                                    nazwy stacji klienta zidentyfikowane przez protokół HTTP,
                                </li>
                                <li>
                                    informacje o użytej przez użytkownika przeglądarce,
                                </li>
                                <li>
                                    adres www strony poprzednio odwiedzanej w przypadku, gdy przejście do stron na
                                    witrynach PCJ nastąpiło z innej witryny poprzez odnośnik,
                                </li>
                                <li>
                                    informacje o błędach, jakie nastąpiły podczas transakcji HTTP.
                                </li>
                            </StyledUnorderedList>
                        </li>

                    </StyledOrderedList>
                </Section>

                <Section>
                    <Header>III. Wykorzystanie danych gromadzonych automatycznie</Header>
                    <StyledOrderedList>
                        <li>
                            Konwent Wojewódzkich Rad Działalności Pożytku Publicznego zapewnia, że dane gromadzone
                            automatycznie w dziennikach serwera z zasady:
                            <StyledUnorderedList>
                                <li>
                                    nie są kojarzone z konkretnymi osobami przeglądającymi nasze witryny,
                                </li>
                                <li>
                                    nie są ujawniane nikomu poza osobami upoważnionymi do administrowania serwerem oraz
                                    głównymi administratorami witryn.
                                </li>
                            </StyledUnorderedList>
                        </li>
                        <li>
                            Administrator serwera i administratorzy serwisów mogą zmierzać do zidentyfikowania
                            konkretnej osoby na podstawie informacji zarejestrowanych w logach serwera tylko w przypadku
                            prób naruszenia integralności systemu plików przez osoby nieuprawnione.
                        </li>
                        <li>
                            Na podstawie plików dzienników mogą być generowane statystyki wspomagające administrowanie
                            witrynami. Statystyki te nie zawierają żadnych cech identyfikujących osoby odwiedzające
                            witryny.
                        </li>
                    </StyledOrderedList>
                </Section>

                <Section>
                    <Header>IV. Wykorzystanie imiennych danych osobowych</Header>
                    <StyledOrderedList>
                        <li>
                            Imienne dane osobowe przetwarzamy tylko i wyłącznie zgodnie z celami, w jakich zostały one
                            powierzone Konwentowi Wojewódzkich Rad Działalności Pożytku Publicznego oraz Stowarzyszeniu
                            „EKOSKOP”, w szczególności:
                            <StyledUnorderedList>
                                <li>
                                    dane podawane przy zgłaszaniu udziału w wydarzeniu: używane są tylko do działań
                                    związanych z organizacją wydarzenia (sporządzenie listy uczestników dla potrzeb
                                    recepcji, komunikowanie się z użytkownikiem w sprawach dotyczących wydarzenia),
                                </li>
                            </StyledUnorderedList>
                        </li>
                        <li>
                            Stowarzyszenie „EKOSKOP” oraz Konwent Wojewódzkich Rad Działalności Pożytku Publicznego
                            dokłada wszelkich starań, aby powierzone nam imienne dane nie dostały się do rąk trzecich,
                            chyba że:
                            <StyledUnorderedList>
                                <li>
                                    Polityka prywatności stanowi inaczej w dalszej części tego dokumentu;
                                </li>
                                <li>
                                    otrzymamy zgodę użytkownika na udostępnienie jego danych;
                                </li>
                                <li>
                                    zostanie złamane prawo i będzie toczyć się związane z tym postępowanie;
                                </li>
                                <li>
                                    wszystkie lub którakolwiek z witryn zostaną wykupione przez stronę trzecią z
                                    zastrzeżeniem, że strona trzecia będzie używała Danych zgodnie z naszą Polityką
                                    prywatności, która jest umową między nami a rejestrującymi się użytkownikami i w
                                    związku z tym stanowi zobowiązanie prawne.
                                </li>
                            </StyledUnorderedList>
                        </li>
                    </StyledOrderedList>
                </Section>

                <Section>
                    <Header>V. Edycja i usuwanie danych osobowych</Header>
                    <StyledOrderedList>
                        <li>
                            Każdy użytkownik ma możliwość podglądu powierzonych nam danych osobowych oraz możliwość ich
                            poprawiania.
                        </li>
                        <li>
                            Każdy użytkownik ma również prawo żądać usunięcia jego danych osobowych z naszych baz
                            danych. Żądanie należy skierować za pomocą poczty elektronicznej na adres <a
                            href="mailto:ekoskop@ekoskop.rzeszow.pl">ekoskop@ekoskop.rzeszow.pl</a>,
                            podając
                            informację, którego konta dotyczy żądanie (nazwa witryny, na której dokonano rejestracji,
                            nazwa konta).
                        </li>
                    </StyledOrderedList>
                </Section>
            </Content>

            <HighlightedContent>
                <Section>
                    <Header>VI. Ciasteczka [Cookies]</Header>
                    <StyledOrderedList>
                        <li>
                            Kiedy użytkownik wchodzi na strony naszych witryn, na dysku jego komputera mogą być
                            zapisywane tzw. ciasteczka (ang. cookies), czyli niewielkie pliki tekstowe wysyłane przez
                            nasz serwer albo serwisy, do których odwołujemy się w naszych witrynach, np. pokazując
                            multimedia.
                        </li>
                        <li>
                            Ciasteczka i podobne technologie używane są w witrynach internetowych przede wszystkim ze
                            względu na wygodę użytkowników, m.in. po to, by jak najlepiej dostosować treści i funkcje
                            witryny do ich potrzeb i oczekiwań, a także w celach statystycznych.
                        </li>
                        <li>
                            Pliki ciasteczek wysyłamy wyjątkowo. Służą one tworzeniu statystyk, które pomagają
                            dostosować zawartość stron do oczekiwań użytkowników, zapamiętaniu preferowanego wyglądu
                            stron, np. ustawionego rozmiaru czcionki, odnotowaniu faktu głosowania w prowadzonych
                            ankietach albo wykonaniu przez użytkownika kilkuetapowych czynności (np. dodania artykułu,
                            dokonania zakupu w naszym sklepie internetowym). Korzystamy również z ciasteczek służących
                            dopasowaniu wyświetlanych reklam do zainteresowań odwiedzających witryny.
                        </li>
                        <li>
                            Oprócz ciasteczek wysyłanych z naszego serwera za pośrednictwem naszych witryn, pliki
                            ciasteczek mogą być wysyłane także z serwerów stron, do których się odwołujemy, np. YouTube
                            czy serwisów społecznościowych.
                        </li>
                        <li>
                            Pliki ciasteczek nie gromadzą żadnych danych osobowych, w tym nazwisk i adresów e-mail.
                        </li>
                        <li>
                            Informacji zapisywanych w plikach ciasteczek nigdzie nie gromadzimy i w żaden sposób nie
                            przetwarzamy.
                        </li>
                        <li>
                            Każdy użytkownik może zmienić ustawienia dotyczące ciasteczek w używanej przez siebie
                            przeglądarce, w tym zupełnie wyłączyć możliwość ich zapisywania.
                        </li>
                        <li>
                            Jeśli użytkownik nie wyłączy możliwości zapisywania ciasteczek pochodzących z naszych
                            witryn, oznacza to jego zgodę na ich zapisywanie i przechowywanie w jego komputerze.
                        </li>
                        <li>
                            Jeżeli użytkownik zdecyduje się na odrzucenie wszystkich ciasteczek, nie będzie możliwe
                            korzystanie z niektórych treści i usług udostępnianych w naszych witrynach, w szczególności
                            wymagających logowania. Wyłączenie ciasteczek nie powoduje natomiast braku możliwości
                            przeglądania stron w naszych witrynach.
                        </li>
                    </StyledOrderedList>
                </Section>
            </HighlightedContent>

            <Content>
                <Section>
                    <Header>VII. Zastrzeżenia</Header>
                    <StyledOrderedList>
                        <li>
                            W Internecie nie można zagwarantować, że każda transmisja danych jest całkowicie bezpieczna.
                            Dlatego też, mimo dokładania wszelkich starań w celu zapewnienia ochrony danych osobowych,
                            nie możemy zapewnić lub zagwarantować bezpieczeństwa informacji przekazywanych do i z
                            naszych witryn. Mogą pojawić się czynniki poza naszą kontrolą, które spowodują udostępnienie
                            danych. W związku z tym zalecamy użytkownikom nieudostępnianie w ramach swojego konta
                            informacji, które nie powinny zostać ujawnione.
                        </li>
                        <li>
                            Publikowane przez Stowarzyszenie „EKOSKOP” witryny zawierają odnośniki do innych stron WWW.
                            Stowarzyszenie „EKOSKOP” nie ponosi żadnej odpowiedzialności za zasady zachowania
                            prywatności obowiązujące na tych stronach. Niniejsza polityka prywatności dotyczy tylko i
                            wyłącznie wyszczególnionych we wprowadzeniu witryn Stowarzyszenia „EKOSKOP” oraz Konwentu
                            Wojewódzkich Rad Działalności Pożytku Publicznego. Namawiamy, by po przejściu z naszych
                            witryn na inne strony, zapoznać się z ustaloną tam polityką prywatności.
                        </li>
                    </StyledOrderedList>
                </Section>

                <Section>
                    <Header>VIII. Kontakt z Administratorem Danych Osobowych</Header>
                    <StyledOrderedList>
                        <li>
                            Administratorem danych osobowych przekazywanych przez użytkowników witryn internetowych
                            Stowarzyszenie „EKOSKOP”, wpisane do Krajowego Rejestru Sądowego pod numerem 0000273010,
                            numer REGON: 180201486, z siedzibą w Rzeszowie, 35-233 Rzeszów, ul. Lubelska 28/4 lok.1 , nr
                            telefonu +48 607 039 990, e-mail: <a
                            href="mailto:ekoskop@ekoskop.rzeszow.pl">ekoskop@ekoskop.rzeszow.pl</a>.
                        </li>
                        <li>
                            Pytania dotyczące naszej Polityki prywatności prosimy kierować do administratora serwisu na
                            podany powyżej adres e-mail.
                        </li>
                    </StyledOrderedList>
                </Section>
            </Content>
        </Wrapper>
    </>
);

export default CookiesPage;