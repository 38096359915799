import React from 'react';
import styled from 'styled-components';
import { centeredSection } from '../styles/mixins';
import { Button, Divider } from '../styles/cssComponents';
import { Link } from 'react-router-dom';
import { down } from 'styled-breakpoints';

const Wrapper = styled.div`
  height: 400px;
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;

  ${centeredSection()};
  
  background-image: url('/assets/backgroundImage.svg');
  background-clip: inherit;
  background-size: 1400px;
  background-repeat: no-repeat;
  background-position-x: calc(620px + (100vw - 1280px) /2);
  background-position-y: bottom;
`;

const Grid = styled.div`
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  padding: 1.5rem;
  display: grid;
  justify-items: center;
  text-align: center;
  grid-row-gap: 1rem;
  
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: .5rem;
  
  ${down('md')} {
    left: 50%;
  }
`;

const NewsletterTitle = styled.h3`
    font-size: 34px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700; 
`;

const NewsletterDivider = styled(Divider)`
  background-color: ${({theme}) => theme.colors.green2};
  width: 50%;
`;

const NewLetterSign = () => (
    <Wrapper>
            <Grid>
                <NewsletterTitle>Powiadamiacz</NewsletterTitle>
                <NewsletterDivider/>
                <p>Chcesz być na bieżąco z nowościami umieszczanymi na naszej stronie, to zapisz się tutaj:</p>
                <Link to={'/newsletter'}>
                    <Button>Zapisz się</Button>
                </Link>
            </Grid>
    </Wrapper>
);

export default NewLetterSign;