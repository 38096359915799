import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import DocList from '../../components/DocList';
import { getStrategicDocuments } from '../../services/apiService';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const DokumentySPage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getStrategicDocuments().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/dokumentyS.jpeg'/>
            <Wrapper>
                <PageHeader>Dokumenty strategiczne</PageHeader>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
};

export default DokumentySPage;