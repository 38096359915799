import {createGlobalStyle} from 'styled-components';
import {down} from 'styled-breakpoints';

export default createGlobalStyle`
  html {
    font-family: Roboto -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    font-size: ${({theme}) => theme.fontSize};
    line-height: ${({theme}) => theme.lineHeight};
  }

  body {
    overflow-y: scroll;
  }

  * {
    letter-spacing: ${({theme}) => theme.letterSpacing};
  }

  #root {
    background: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.foreground};
  }

  button {
    font-size: 1rem;
  }

  a {
    color: ${({theme}) => theme.colors.green6};
    text-decoration: none;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .leaflet-div-icon {
    background: transparent;
    border: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .fc {
    --fc-button-text-color: ${({theme}) => theme.colors.foreground};

    .fc-button-primary:not(:disabled) {
      &.fc-button-active, &:hover {
        --fc-button-text-color: ${({theme}) => theme.contrast ? theme.colors.inverseForeground : theme.colors.foreground};
      }
    }

    .fc-header-toolbar {
      flex-wrap: wrap;

      ${down('md')} {
        .fc-toolbar-chunk {
          display: flex;
          flex-basis: 100%;
          justify-content: center;
          margin-bottom: 8px;

          .fc-today-button {
            flex-grow: 1;
          }

          .fc-prev-button, .fc-next-button {
            width: 80px;
          }
        }

        .fc-toolbar-chunk:nth-child(3) .fc-button-group {
          flex-basis: 100%;
        }

        .fc-toolbar-chunk:nth-child(2) {
          order: -1;
        }
      }
    }

    .fc-day-today {
      &, & a {
        color: black;
      }
    }

    .fc-col-header-cell-cushion {
      color: ${({theme}) => theme.colors.foreground};
    }
  }

  :root {
    --fc-button-text-color: black;
    --fc-button-bg-color: transparent;
    --fc-button-active-bg-color: ${({theme}) => theme.colors.green6};
    --fc-button-hover-bg-color: ${({theme}) => theme.colors.green6};
    --fc-today-bg-color: ${({theme}) => theme.contrast ? theme.colors.green1 : theme.colors.green1 + '19'};
  }
`;