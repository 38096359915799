import React from 'react';
import styled from 'styled-components';
import { centeredSection } from '../styles/mixins';
import { Divider } from '../styles/cssComponents';
import { down, up } from 'styled-breakpoints';

const Wrapper = styled.div`
  ${centeredSection()};
  background: ${({theme}) => theme.colors.footerBackground};
`;

const ImagesGridTop = styled.div`
  display: grid;
  grid-gap: 2rem;
  align-items: center;

  
  img {
    max-width: 100%;
  }

  grid-template-columns: 1fr 1fr;

  ${up('md')} {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 4rem;
  }
`;

const ImagesGridBottom = styled.div`
  display: grid;
  grid-gap: 2rem;
  align-items: center;

  img {
    max-width: 100%;
  }
  
  grid-template-columns: 1fr 1fr;
  ${up('md')} {
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem 12rem 1rem;
  }
  
  ${down('md')} {
    margin-top: 2rem;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 4rem 0;
`;

const Sponsors = () => {
    return (
        <Wrapper>
            <StyledDivider/>

            <ImagesGridTop>
                <img src="/assets/partner1.png" alt="partner1"/>
                <img src="/assets/partner2.png" alt="partner2"/>
                <img src="/assets/partner3.png" alt="partner3"/>
                <img src="/assets/partner4.png" alt="partner4"/>
            </ImagesGridTop>

            <ImagesGridBottom>
                <img src="/assets/partner5.png" alt="partner5"/>
                <img src="/assets/partner6.png" alt="partner6"/>
                <img src="/assets/partner7.png" alt="partner7"/>
            </ImagesGridBottom>
        </Wrapper>
    );
}

export default Sponsors;