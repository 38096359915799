import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getANews } from '../../services/apiService';
import { News } from '../../services/model';
import { PageHeader } from '../../styles/cssComponents';
import styled from 'styled-components';
import PageImage from 'components/PageImage';
import { down } from 'styled-breakpoints';
import { centeredSection } from '../../styles/mixins';
import {getIdFromUrlTitle} from "../../services/newsService";

const Wrapper = styled.div`
${centeredSection()}
`;

const Content = styled.div`
  max-width: 850px;
  word-break: break-word;
  margin: 0 auto;

  > * + * {
    margin-top: 10px;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 850px;
  margin: 0 auto 2rem;
  
  ${down('md')} {
    max-width: 100%;
  }
`;

const NewsPage = () => {
    let {urlTitle} = useParams();
    const [news, setNews] = useState<News>(null);

    useEffect(() => {
        const id = getIdFromUrlTitle(urlTitle);
        console.log(urlTitle)
        getANews(id).then(data => {
            setNews(data);
        })
    }, [urlTitle])

    return (
        <div>
            <PageImage url='/assets/aktualnosci.jpeg'/>
            {news && (
                <Wrapper>
                    <PageHeader>{news.title.rendered}</PageHeader>
                    <Image src={news.acf.image} alt=""/>
                    <Content dangerouslySetInnerHTML={{__html: news.acf.description}} />
                </Wrapper>
            )}
        </div>
    );
};

export default NewsPage;