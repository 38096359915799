import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Divider, PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import PageImage from 'components/PageImage';
import {useSearchParams} from 'react-router-dom';
import IconInput from '../../components/IconInput';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {getSearch} from "../../services/apiService";
import DocList from "../../components/DocList";

const Wrapper = styled.div`
  ${centeredSection()};

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Summary = styled.p`
  margin: 1rem 0;
`;

const SummaryDivider = styled(Divider)`
  background-color: ${({theme}) => theme.colors.green2};
  width: 20%;
  margin-bottom: 3rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchBarContainer = styled.div`
  max-width: 700px;
  width: 100%;
`;

const SzukajPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);

    const handleInput = event => {
        setSearchQuery(event.target.value);
    };

    const handleSearch = () => {
        setSearchParams({q: searchQuery});
    };

    useEffect(() => {
        const query = searchParams.get('q');
        setSearchQuery(query);
        search(query);
    }, [searchParams]);

    const search = query => {
        if (query.trim().length >= 3) {
            getSearch(query).then(result => {
                setSearchResults(result);
            });
        } else {
            setSearchResults([]);
        }
    };

    return (
        <>
            <PageImage url="/assets/wyniki-wyszukiwania.jpeg"/>
            <Wrapper>
                <PageHeader>Wyniki wyszukiwania</PageHeader>
                <Content>
                    <SearchBarContainer>
                        <IconInput icon={faSearch} placeholder="Wpisz frazę do wyszukania..." onInput={handleInput}
                                   emitClickOnEnter
                                   onIconClick={handleSearch} value={searchQuery}/>
                    </SearchBarContainer>
                    {searchResults != null &&
                        <>
                            <Summary>Znaleziono wyników: {searchResults.length}</Summary>
                            <SummaryDivider/>
                            {searchResults?.length > 0 &&
                                <DocList items={searchResults}/>
                            }
                        </>
                    }
                </Content>
            </Wrapper>
        </>
    );
};

export default SzukajPage;