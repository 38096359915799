import React from 'react';
import styled from 'styled-components';
import { centeredSection } from 'styles/mixins';
import NewLetterSign from '../../components/NewLetterSign';
import Footer from '../../components/Footer';
import NewsSection from '../../components/NewsSection';
import { up } from 'styled-breakpoints';

const MainSection = styled.div`
  position: relative;
  ${centeredSection()};
  background-color: ${({theme}) => theme.colors.grey};

  background-image: url('/assets/backgroundImage.svg');
  background-clip: inherit;
  min-height: 400px;
  background-size: 1400px;
  background-repeat: no-repeat;
  background-position-x: calc(620px + (100vw - 1280px) / 2);
  background-position-y: bottom;
`;

const Title = styled.h2`
  font-size: 32px;
`;

const Grid = styled.div`
  display: grid;
  padding: 44px 0;
  grid-row-gap: 1rem;

  ${up('md')} {
    width: 37rem;
  }
`;

const InfoSection = styled.div`
  ${centeredSection()};
  background-color: ${({theme}) => theme.colors.blue};
  color: white;
`;

const InfoGrid = styled.div`
  display: grid;
  padding: 30px;
  text-align: center;

  justify-items: center;
  row-gap: 2.5rem;

  ${up('md')} {
    grid-auto-columns: 1fr auto 1fr auto 1fr auto 1fr;
    grid-auto-flow: column;
    grid-column-gap: 3rem;
  }
`;

const InfoSubGrid = styled.div`
  display: grid;
  grid-row-gap: .5rem;
`;

const InfoNumber = styled.p`
  font-size: 36px;
  color: ${({theme}) => theme.colors.green3};
`;

const InfoText = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.inverseForeground};
`;

const Divider = styled.div`
  background-color: ${({theme}) => theme.colors.green3};

  height: 1px;
  width: 60%;

  ${up('md')} {
    width: 1px;
    height: auto;
  }
`;

const Home = () => {
    return (
        <>
            <MainSection>
                <Grid>
                    <Title>Konwent WRDPP</Title>
                    <p>Konwent WRDPP jest dobrowolnym porozumieniem 16 wojewódzkich Rad Działalności Pożytku
                        Publicznego i nie posiada instytucjonalnej osobowości prawnej.
                        Celem Konwentu WRDPP jest tworzenie płaszczyzny współpracy
                        i wymiany informacji dla członków wojewódzkich Rad Działalności Pożytku Publicznego, a także
                        wyrażania wspólnych opinii w sprawach istotnych dla funkcjonowania Rad, a także w zakresie
                        współpracy administracji publicznej i organizacji pozarządowych.</p>
                </Grid>
            </MainSection>
            <InfoSection>
                <InfoGrid>
                    <InfoSubGrid>
                        <InfoNumber>16</InfoNumber>
                        <InfoText>Rad Konwentu WRDPP</InfoText>
                    </InfoSubGrid>

                    <Divider/>

                    <InfoSubGrid>
                        <InfoNumber>11</InfoNumber>
                        <InfoText>Zespołów tematycznych</InfoText>
                    </InfoSubGrid>

                    <Divider/>

                    <InfoSubGrid>
                        <InfoNumber>70</InfoNumber>
                        <InfoText>Partnerów regionalnych i krajowych</InfoText>
                    </InfoSubGrid>

                    <Divider/>

                    <InfoSubGrid>
                        <InfoNumber>31.000</InfoNumber>
                        <InfoText>Odbiorców działań Konwentu</InfoText>
                    </InfoSubGrid>
                </InfoGrid>
            </InfoSection>
            <NewsSection/>
            <NewLetterSign/>
            <Footer/>
        </>
    );
}

export default Home
