import { createContext } from "react";

interface ITheme {
    lineHeight: string | number;
    fontSize: string | number;
    letterSpacing: string | number;
    colors: {[key: string]: string | boolean};
    contrast: boolean,
    toggleExtraLetterSpacing: Function,
    toggleExtraFontSize: Function,
    toggleExtraLineHeight: Function,
    toggleExtraContrast: Function,
}

const ThemeContext = createContext({
    theme: {} as ITheme,
    updateTheme: (theme) => {}
});

export default ThemeContext;