import {centeredSection} from "../../styles/mixins";
import React, {useEffect, useState} from "react";
import {getDepartmentsAssistants} from "../../services/apiService";
import { CenteredText, PageHeader } from "../../styles/cssComponents";
import styled from "styled-components";
import ContactList from "../../components/ContactList";
import PageImage from "components/PageImage";

const Wrapper = styled.div`
  ${centeredSection()};
`;

const PelnomocnicyWojewodowPage = () => {
    const [assistants, setAssistants] = useState([]);

    useEffect(() => {
        getDepartmentsAssistants().then(data => {
            setAssistants(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/polnomocnicy-wojewodow.jpeg'/>
            <Wrapper>
                <PageHeader>Pełnomocnicy Wojewodów</PageHeader>
                <CenteredText>Adresy i inne informacje kontaktowe do Pełnomocników Wojewodów.</CenteredText>
                <ContactList offices={assistants} width="70%"/>
            </Wrapper>
        </>
    )
};

export default PelnomocnicyWojewodowPage;
