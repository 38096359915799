import PageImage from 'components/PageImage';
import React from 'react';
import styled from 'styled-components';
import {PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import {down} from "styled-breakpoints";

const Wrapper = styled.div`
  ${centeredSection()};
  width: 80%;
  margin: 0 auto;
  text-align: justify;

  ${down('md')} {
    width: 100%;
  }
`;

const ContentSection = styled.p`
  margin-top: .5rem;
  margin-bottom: 2rem;

  ol {
    margin-left: 2rem;
  }
`;

const Paragraph = styled.h3`
  text-align: center;
  font-weight: bold;
`;

const ListWithLetters = styled.ol`
  list-style: lower-alpha;
`;

const StatutPage = () => (
    <>
        <PageImage url='/assets/regulamin.jpeg'/>

        <Wrapper>
            <PageHeader>Regulamin</PageHeader>

            <Paragraph>&sect; 1</Paragraph>
            <Paragraph>Postanowienia ogólne</Paragraph>
            <ContentSection>
                <ol>
                    <li>Konwent Wojewódzkich Rad Działalności Pożytku Publicznego, zwany dalej „Konwentem WRDPP”, działa
                        na podstawie niniejszego Regulaminu.
                    </li>
                    <li>Konwent WRDPP jest dobrowolnym porozumieniem wojewódzkich Rad Działalności Pożytku Publicznego i
                        nie posiada osobowości prawnej.
                    </li>
                    <li>Konwent WRDPP jest płaszczyzną współpracy, wymiany informacji i doświadczeń oraz wypracowywania
                        wspólnych stanowisk w sprawach będących sferą zainteresowania wojewódzkich Rad Działalności
                        Pożytku Publicznego.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 2</Paragraph>
            <Paragraph>Zadania Konwentu RDPP</Paragraph>
            <ContentSection>
                <p>Do zadań Konwentu WRDPP należy przede wszystkim:</p>
                <ol>
                    <li>Wzmocnienie współpracy pomiędzy wojewódzkimi RDPP.</li>
                    <li>Podniesienie roli RDPP.</li>
                    <li>Wzmocnienie jakości pracy RDPP.</li>
                    <li>Konsultowanie projektów aktów prawnych, zwłaszcza o wymiarze krajowym, dotyczących współpracy
                        administracji publicznej i organizacji pozarządowych.
                    </li>
                    <li>Wyrażanie stanowisk w sprawach istotnych dla funkcjonowania wojewódzkich RDPP, będących w sferze
                        ich zainteresowania, a także w zakresie współpracy administracji publicznej i organizacji
                        pozarządowych.
                    </li>
                    <li>Współpraca z Radą Działalności Pożytku Publicznego działającą przy ministrze właściwym ds.
                        zabezpieczenia społecznego, współpraca z innymi RDPP oraz ciałami dialogu obywatelskiego, a
                        także innymi partnerami społecznymi.
                    </li>
                    <li>Pomoc we wdrażaniu Strategicznej Mapy Drogowej Rozwoju Sektora Obywatelskiego
                        w Polsce.
                    </li>
                    <li>Patronowanie i wsparcie organizacji corocznego Ogólnopolskiego Święta Rad Działalności Pożytku
                        Publicznego – 27 listopada.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 3</Paragraph>
            <Paragraph>Skład Konwentu WRDPP</Paragraph>
            <ContentSection>
                <ol>
                    <li>Członkami Konwentu WRDPP są wojewódzkie RDPP, które podjęły uchwałę o przystąpieniu do Konwentu
                        i przedstawiły ją Prezydium Konwentu WRDPP.
                    </li>
                    <li>Rada, będąca członkiem Konwentu WRDPP, reprezentowana jest przez dwóch przedstawicieli, po
                        jednym ze strony samorządu i ze strony organizacji pozarządowych, reprezentowanych w danej
                        Radzie.
                    </li>
                    <li>Każdy przedstawiciel wskazuje swojego zastępcę spośród innych członków danej Rady. Zastępcy
                        przysługują takie same prawa i obowiązki jak przedstawicielowi.
                    </li>
                    <li>Członek Konwentu WRDPP może w każdej chwili odwołać swoich przedstawicieli, informując o tym
                        przedstawiciela i Konwent WRDPP co najmniej 14 dni wcześniej.
                    </li>
                    <li>Funkcjonowanie Konwentu WRDPP opiera się na pracy społecznej przedstawicieli. Wydatki poniesione
                        przez przedstawiciela (lub jego zastępcę) w związku z udziałem w posiedzeniach Konwentu WRDPP
                        mogą podlegać refundacji na podstawie decyzji Rady reprezentowanej przez przedstawiciela.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 4</Paragraph>
            <Paragraph>Organizacja prac Konwentu WRDPP</Paragraph>
            <ContentSection>
                <ol>
                    <li>Zwyczajne posiedzenia Konwentu WRDPP odbywają się co najmniej dwa razy w roku (w każdym półroczu
                        co najmniej jedno).
                    </li>
                    <li>Obsługę administracyjną Konwentu WRDPP zapewniają poszczególne wojewódzkie RDPP przez okres
                        połowy roku, zgodnie z harmonogramem zatwierdzonym przez Konwent.
                    </li>
                    <li>Obsługa administracyjna Konwentu WRDPP polega na przygotowaniu i przeprowadzaniu posiedzenia
                        Konwentu w porozumieniu z Prezydium Konwentu.
                    </li>
                    <li>Zwyczajne posiedzenia Konwentu WRDPP zwołuje Prezydium Konwentu WRDPP w porozumieniu z
                        wojewódzką RDPP obsługującą Konwent WRDPP.
                    </li>
                    <li>Nadzwyczajne posiedzenie Konwentu WRDPP musi zostać zwołane na wniosek co najmniej połowy
                        członków Konwentu WRDPP.
                    </li>
                    <li>Konwent WRDPP obraduje w obecności co najmniej połowy swoich członków.</li>
                    <li>W pracach Konwentu WRDPP uczestniczyć mogą goście i eksperci zapraszani przez zwołujących
                        posiedzenie.
                    </li>
                    <li>Informacja o czasie, miejscu i proponowanym porządku posiedzenia Konwentu WRDPP powinna zostać
                        doręczona w sposób skuteczny wszystkim członkom Konwentu na co najmniej 30 dni przed planowanym
                        posiedzeniem.
                    </li>
                    <li>Każdy członek Konwentu WRDPP dysponuje jednym głosem.</li>
                    <li>Decyzje Konwentu WRDPP zapadają zwykłą większością głosów w głosowaniu jawnym lub tajnym, w
                        zależności od decyzji członków Konwentu WRDPP.
                    </li>
                    <li>Dokumentacja Konwentu WRDPP jest gromadzona przez Przewodniczącego Prezydium Konwentu.</li>
                    <li>Sekretariatem Konwentu WRDPP jest biuro Przewodniczącego Prezydium lub biuro Sieci Wspierania
                        Organizacji Pozarządowych SPLOT.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 5</Paragraph>
            <Paragraph>Prezydium Konwentu RDPP</Paragraph>
            <ContentSection>
                <ol>
                    <li>Prezydium Konwentu WRDPP składa się z 3 do 5 przedstawicieli członków Konwentu wybranych w
                        wyborach tajnych na dwuletnią kadencję. Konwent WRDPP dokonuje wyboru
                        Przewodniczącej/Przewodniczącego Prezydium, która/y odpowiedzialna/y jest za pracę Prezydium.
                    </li>
                    <li>Prezydium Konwentu WRDPP:</li>
                    <ListWithLetters>
                        <li>reprezentuje Konwent WRDPP na zewnątrz,</li>
                        <li>zwołuje posiedzenia Konwentu WRDPP w porozumieniu z wojewódzką RDPP obsługującą Konwent
                            WRDPP,
                        </li>
                        <li>opracowuje projekty stanowisk Konwentu WRDPP i przedstawia je pod obrady na posiedzeniu lub
                            w drodze obiegowej, a zatwierdzone przez Konwent WRDPP stanowiska przekazuje odpowiednim
                            adresatom,
                        </li>
                        <li>przedstawia roczne sprawozdania z pracy Konwentu WRDPP,</li>
                        <li>prowadzi dokumentację Konwentu WRDPP,</li>
                        <li>podejmuje inne działania wskazane przez Konwent WRDPP.</li>
                    </ListWithLetters>
                    <li>W przypadku ustąpienia członka/członków Prezydium, Konwent WRDPP na najbliższym posiedzeniu
                        wybiera nowego członka/członków.
                    </li>
                    <li>Prezydium spotyka się według potrzeb, co najmniej raz na kwartał.</li>
                    <li>Lokal na posiedzenia Prezydium udostępnia Sieć Wspierania Organizacji Pozarządowych SPLOT.</li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 6</Paragraph>
            <Paragraph>Przebieg posiedzeń Konwentu WRDPP</Paragraph>
            <ContentSection>
                <ol>
                    <li>Przewodniczący i protokolant posiedzenia Konwentu WRDPP są wybierani podczas spotkania spośród
                        przedstawicieli członków Konwentu WRDPP.
                    </li>
                    <li>Przewodniczący Konwentu WRDPP otwiera, prowadzi i zamyka posiedzenie Konwentu oraz zatwierdza
                        porządek obrad.
                    </li>
                    <li>Wnioski formalne są niezwłocznie poddawane pod głosowanie.</li>
                    <li>Posiedzenia Konwentu WRDPP są protokołowane.</li>
                    <li>Konwent WRDPP może podejmować decyzje związane ze swoim funkcjonowaniem i wyrażać stanowiska w
                        sposób obiegowy lub inny uzgodniony podczas posiedzenia Konwentu WRDPP.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 7</Paragraph>
            <Paragraph>Wyrażanie stanowisk przez Konwent WRDPP</Paragraph>
            <ContentSection>
                <ol>
                    <li>Konwent WRDPP opracowuje swoje stanowiska, w tym dotyczące konsultacji projektów aktów prawnych,
                        które przekazywane są do odpowiednich adresatów, a także upubliczniane na stronach
                        internetowych.
                    </li>
                    <li>Z inicjatywą podjęcia stanowiska może wystąpić każdy członek Konwentu WRDPP, zwany dalej
                        „Wnioskodawcą”, podczas posiedzenia Konwentu WRDPP lub w trybie obiegowym.
                    </li>
                    <li>Wnioskodawca przygotowuje projekt stanowiska Konwentu WRDPP i dokonuje jego konsultacji z
                        pozostałymi członkami Konwentu WRDPP.
                    </li>
                    <li>Projekt stanowiska po naniesieniu ewentualnych poprawek staje się oficjalnym stanowiskiem
                        Konwentu WRDPP po zaakceptowaniu treści przez co najmniej połowę członków Konwentu.
                    </li>
                    <li>Stanowiska Konwentu WRDPP wraz z protokołem z obrad i innymi materiałami są przekazywane do
                        wszystkich członków Konwentu.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 8</Paragraph>
            <Paragraph>Logotyp</Paragraph>
            <ContentSection>
                <ol>
                    <li>Logotyp Konwentu WRDPP jest stosowany we wszystkich dokumentach Konwentu.</li>
                    <li>Logotyp Konwentu WRDPP może być stosowany bezpłatnie także w innych sytuacjach, niż określona w
                        ust. 1, za zgodą Prezydium Konwentu WRDPP, w szczególności na stronach internetowych i
                        materiałach promocyjnych podmiotów współpracujących z Konwentem WRDPP.
                    </li>
                </ol>
            </ContentSection>

            <Paragraph>&sect; 9</Paragraph>
            <Paragraph>Postanowienia końcowe</Paragraph>
            <ContentSection>
                <ol>
                    <li>Wnioski w sprawie zmian w Regulaminie przyjmuje Konwent WRDPP większością 2/3 obecnych na
                        posiedzeniu przedstawicieli.
                    </li>
                    <li>Zmiany w Regulaminie przyjmowane są zwykłą większością głosów.</li>
                    <li>Regulamin wchodzi w życie z dniem podjęcia.</li>
                </ol>
            </ContentSection>
        </Wrapper>
    </>
);

export default StatutPage;