import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { centeredSection, desktopOnly, mobileOnly } from 'styles/mixins';
import useTheme from '../styles/useTheme';
import { down, up } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArchive, faArrowsUpDown,
    faBars, faBell, faChess, faChevronLeft, faCircleInfo, faFileInvoice, faFileLines,
    faFolderOpen, faInfo, faLaptop, faListCheck,
    faLocationDot, faPodcast, faTextHeight, faTextWidth, faThumbsUp,
    faUniversalAccess,
    faUser,
    faUserGroup, faVideo, faXmark,
} from '@fortawesome/free-solid-svg-icons';


const Wrapper = styled.header`
  height: 70px;

  ${up('md')} {
    height: 90px;
  }

  position: relative;

  ${up('md')} {
    ${centeredSection()}
  }
`;

const linkStyles = css`
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-out;
  
  :hover {
    background: ${({theme}) => theme.contrast ? theme.colors.background : theme.colors.green6};
    color: ${({theme}) => theme.contrast ? theme.colors.green2 : theme.colors.inverseForeground};
  }
`;

const LinkText = styled.a`
  position: relative;
  ${linkStyles};
  color: ${({theme}) => theme.colors.foreground};

  ${({active}) => active && css`
    ::after {
      position: absolute;
      height: 4px;
      width: 80%;
      top: 100%;
      left: 10%;
      background-color: green;
      content: '';
    }
  `}
`;

const Logo = styled.img`
  margin-right: 50px;
  cursor: pointer;
  height: 90px;
`

const NavLink = ({route, name, onMouseEnter, onClick = null}) => {
    return (
        <Link to={route} onMouseEnter={onMouseEnter} onClick={onClick}>
            <LinkText >{name}</LinkText>
        </Link>
    );
};

const SubLinkBlock = styled.a`
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  height: 90px;
  width: 290px;
  display: flex;
  cursor: pointer;
  
  background-color: ${props => {
    if (props.theme.contrast) {
      return props.theme.colors.background;
    }
    return props.secondary? 'white' : props.theme.colors.blue;
  }};

  color: ${props => {
    if (props.theme.contrast) {
      return props.theme.colors.foreground;
    }
    return props.secondary? props.theme.colors.foreground : props.theme.colors.inverseForeground;
  }};

  border: ${props => {
    if (props.theme.contrast) {
      return '1px solid';
    }
    return 'none';
  }};

  transition: all 0.2s ease-in-out;
  outline: none;

  * + * {
    margin-left: 1rem;
  }

  justify-content: center;
  align-items: center;
  border-radius: 15px;

  :hover {
    color: ${({theme}) => theme.contrast ? theme.colors.green2 : theme.colors.inverseForeground};
    background: ${({theme}) => theme.contrast ? theme.colors.inverseBackground : theme.colors.green6};
  }

  ${down('md')} {
    height: 60px;
  }
`;

const SubLink = ({route, name, secondaryColor = false, icon = undefined, onClick = null}) =>  {
    return (
        <Link to={route} onClick={onClick}>
            <SubLinkBlock secondary={secondaryColor}>
                {icon && <FontAwesomeIcon fontSize={25} icon={icon}/>}
                <span>{name}</span>
            </SubLinkBlock>
        </Link>
    );
};


const SubNav = styled.div`
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.green4};

  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  ${centeredSection()};
`;

type MenuMode = '' | 'o-konwencie' | 'o-projekcie' | 'baza-wiedzy' | 'dostepnosc';

const NotLink = styled.p`
  ${linkStyles};
`;

const DesktopMenu = styled.div`
  ${desktopOnly('flex')};
  align-items: center;
  justify-content: space-between;
`;

const MobileMenu = styled.div`
  ${mobileOnly()};
  position: relative;
`;

const LogoMobile = styled(Logo)`
  height: 70px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const SubNavMobile = styled(SubNav)`
  position: absolute;
  height: calc(100vh - 70px);
  top: 70px;
  left: 0;
  right: 0;
  align-content: start;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
`;

const BackIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 1.75rem;
  left: 2rem;
  bottom: 2rem;
  color: white;
`;

const StyledHamburger = styled(FontAwesomeIcon)`
  font-size: 1.75rem;
  margin: 1.25rem;
  cursor: pointer;
`;

const StyledAccessibility = styled(FontAwesomeIcon)`
  font-size: 1.75rem;
  cursor: pointer;
  padding: 10px 20px;
  transition: all 0.2s ease-out;

  :hover {
    color: ${({theme}) => theme.colors.green6};
  }
`;

const Header = () => {
    const {update} = useTheme();

    const [mode, setMode] = useState<MenuMode>('');
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    const clearMenu = () => {
        setMode('');
        setMobileMenuOpen(false);
    }

    const preventCloseMenu = (cb) => (e) => {
        e.stopPropagation();
        cb();
    }

    return (
        <Wrapper>
            <DesktopMenu>
                <Link to="/">
                    <Logo src="/assets/logo.png" alt="Logo"/>
                </Link>

                <NotLink onMouseEnter={() => setMode('o-konwencie')}>O KONWENCIE</NotLink>
                <NavLink onMouseEnter={() => setMode('')} route="/mapa-teleadresowa" name="MAPA TELEADRESOWA" onClick={() => setMode('')}/>
                <NavLink onMouseEnter={() => setMode('')} route="/czasoumilacz" name="CZASOUMILACZ" onClick={() => setMode('')}/>
                <NotLink onMouseEnter={() => setMode('o-projekcie')}>PROJEKTY</NotLink>
                <NotLink onMouseEnter={() => setMode('baza-wiedzy')}>BAZA WIEDZY</NotLink>

                <StyledAccessibility onMouseEnter={() => setMode('dostepnosc')} icon={faUniversalAccess}/>

                {mode && mode !== 'dostepnosc' &&
                    <SubNav onMouseLeave={() => setMode('')}>
                        {mode === 'o-konwencie' && <>
                            <SubLink route="/statut" name="Regulamin" icon={faFileLines} onClick={() => setMode('')}/>
                            <SubLink route="/przedstawiciele" name="Przedstawiciele" icon={faUserGroup} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/prezydium" name="Prezydium" icon={faUser} onClick={() => setMode('')}/>
                            <SubLink route="/biuro-kontakt" name="Biuro / kontakt" icon={faLocationDot} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/uchwaly" name="Uchwały" icon={faFolderOpen} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/informacje" name="Historia" icon={faCircleInfo} onClick={() => setMode('')}/>
                        </>}
                        {mode === 'o-projekcie' && <>
                           
                            <SubLink route="/projekty" name="Projekty" icon={faBell} onClick={() => setMode('')}/>
                            <SubLink route="/archiwum" name="Archiwum" icon={faArchive} secondaryColor onClick={() => setMode('')}/>
                        </>}
                        {mode === 'baza-wiedzy' && <>
                            <SubLink route="/dobre-praktyki" name="Dobre praktyki" icon={faThumbsUp} onClick={() => setMode('')}/>
                            <SubLink route="/dokumenty-strategiczne" name="Dokumenty strategiczne" icon={faChess} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/rekomendacje-zadan-publicznych" name="Rekomendacje zadań publicznych"
                                     icon={faListCheck} onClick={() => setMode('')}/>
                            <SubLink route="/publickacje-elektroniczne" name="Publikacje elektroniczne" icon={faFileInvoice} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/informacja-o-publikacjach-w-biurze"
                                     name="Informacja o publikacjach w biurze"
                                     icon={faInfo} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/opinie-ekspertyzy" name="Opinie / Ekspertyzy" onClick={() => setMode('')}/>
                            <SubLink route="/podcasty" name="Podcasty" icon={faPodcast} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/wideo" name="Wideo" icon={faVideo} onClick={() => setMode('')}/>
                        </>}
                    </SubNav>
                }

                {mode && mode === 'dostepnosc' &&
                    <SubNav onMouseLeave={() => setMode('')}>
                        <SubLinkBlock as={"button"} onClick={() => update('contrast')}>
                            <FontAwesomeIcon icon={faLaptop}/>
                            <span>Kontrast +</span>
                        </SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('fontSize')} secondary>
                            <FontAwesomeIcon icon={faTextHeight}/>
                            <span>Duzy tekst</span>
                        </SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('letterSpacing')}>
                            <FontAwesomeIcon icon={faTextWidth}/>
                            <span>Lekkie odstepy</span>
                            </SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('lineHeight')} secondary>
                            <FontAwesomeIcon icon={faArrowsUpDown}/>
                            <span>Wysokość linii</span></SubLinkBlock>
                    </SubNav>}
            </DesktopMenu>

            <MobileMenu>
                <StyledHamburger fontSize={25} onClick={toggleMobileMenu} icon={mobileMenuOpen ? faXmark : faBars}/>

                <Link to="/">
                    <LogoMobile src="/assets/logo.png" alt="Logo"/>
                </Link>

                {mobileMenuOpen && (<SubNavMobile onClick={clearMenu}>
                        <SubLinkBlock onClick={preventCloseMenu(() => setMode('o-konwencie'))}>O KONWENCIE</SubLinkBlock>
                        <SubLink route="/mapa-teleadresowa" name="MAPA TELEADRESOWA" secondaryColor onClick={() => setMode('')}/>
                        <SubLink route="/czasoumilacz" name="CZASOUMILACZ" onClick={() => setMode('')}/>
                        <SubLinkBlock onClick={preventCloseMenu(() => setMode('o-projekcie'))} secondary>PROJEKTY</SubLinkBlock>

                        <SubLinkBlock onClick={preventCloseMenu(() => setMode('baza-wiedzy'))}>BAZA
                            WIEDZY</SubLinkBlock>
                        <SubLinkBlock onClick={preventCloseMenu(() => setMode('dostepnosc'))} secondary>
                            <FontAwesomeIcon fontSize={25} onClick={() => setMode('dostepnosc')} icon={faUniversalAccess}/>
                            <span>DOSTĘPNOŚĆ</span>
                        </SubLinkBlock>
                    </SubNavMobile>
                )}


                {mobileMenuOpen && mode && mode !== 'dostepnosc' &&
                    <SubNavMobile onClick={clearMenu} onMouseLeave={() => setMode('')}>
                        {mode === 'o-konwencie' && <>
                            <SubLink route="/statut" name="REGULAMIN" icon={faFileLines} onClick={() => setMode('')}/>
                            <SubLink route="/przedstawiciele" name="PRZEDSTAWICIELE" icon={faUserGroup} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/prezydium" name="PREZYDIUM" icon={faUser} onClick={() => setMode('')}/>
                            <SubLink route="/biuro-kontakt" name="BIURO / KONTAKT" icon={faLocationDot} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/uchwaly" name="UCHWAŁY" icon={faFolderOpen} onClick={() => setMode('')}/>
                            <SubLink route="/informacje" name="HISTORIA" icon={faCircleInfo} secondaryColor onClick={() => setMode('')}/>
                        </>}
                        {mode === 'o-projekcie' && <>
                            
                            <SubLink route="/projekty" name="PROJEKTY" icon={faBell} onClick={() => setMode('')}/>
                            <SubLink route="/archiwum" name="ARCHIWUM" icon={faArchive} secondaryColor onClick={() => setMode('')}/>
                        </>}
                        {mode === 'baza-wiedzy' && <>
                            <SubLink route="/dobre-praktyki" name="DOBRE PRAKTYKI" icon={faThumbsUp} onClick={() => setMode('')}/>
                            <SubLink route="/dokumenty-strategiczne" name="DOKUMENTY STRATEGICZNE" icon={faChess} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/rekomendacje-zadan-publicznych" name="REKOMENDACJE ZADAŃ PUBLICZNYCH"
                                     icon={faListCheck} onClick={() => setMode('')}/>
                            <SubLink route="/publickacje-elektroniczne" name="PUBLIKACJE ELEKTRONICZNE" icon={faFileInvoice} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/informacja-o-publikacjach-w-biurze"
                                     name="INFORMACJA O PUBLIKACJACH W BIURZE"
                                     icon={faInfo} onClick={() => setMode('')}/>
                            <SubLink route="/opinie-ekspertyzy" name="OPINIE / EKSPERTYZY" icon={faFileLines} secondaryColor onClick={() => setMode('')}/>
                            <SubLink route="/podcasty" name="PODCASTY" icon={faPodcast} onClick={() => setMode('')}/>
                            <SubLink route="/wideo" name="WIDEO" icon={faVideo} secondaryColor onClick={() => setMode('')}/>
                        </>}
                        <BackIcon onClick={preventCloseMenu(() => setMode(''))} icon={faChevronLeft}/>
                    </SubNavMobile>
                }

                {mode && mode === 'dostepnosc' &&
                    <SubNavMobile onClick={clearMenu} onMouseLeave={() => setMode('')}>
                        <SubLinkBlock as={"button"} onClick={() => update('contrast')}>
                            <FontAwesomeIcon icon={faLaptop}/>
                            <span>KONTRAST +</span></SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('fontSize')} secondary>
                            <FontAwesomeIcon icon={faTextHeight}/>
                            <span>DUŻY TEKST</span></SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('letterSpacing')}>
                            <FontAwesomeIcon icon={faTextWidth}/>
                            <span>LEKKIE ODSTĘPY</span></SubLinkBlock>
                        <SubLinkBlock as={"button"} onClick={() => update('lineHeight')} secondary>
                            <FontAwesomeIcon icon={faArrowsUpDown}/>
                            <span>WYSOKOŚĆ LINII</span></SubLinkBlock>
                        <BackIcon onClick={preventCloseMenu(() => setMode(''))} icon={faChevronLeft}/>
                    </SubNavMobile>}
            </MobileMenu>

        </Wrapper>
    );
};

export default Header;