import PageImage from 'components/PageImage';
import React from 'react';
import styled from 'styled-components';
import {PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import {down} from "styled-breakpoints";

const Wrapper = styled.div`
  ${centeredSection()};
  width: 80%;
  margin: 0 auto;
  text-align: justify;

  ${down('md')} {
    width: 100%;
  }
`;

const ContentSection = styled.p`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const Images = styled.div`
  max-width: 800px;
  margin: 2rem auto 0;

  flex-wrap: wrap;
  display: flex;
  column-gap: 2px;

  img {
    flex-grow: 1;
    max-height: 135px;
    margin-bottom: 2px;
  }
`;

const InformacjePage = () => (
    <>
        <PageImage url='/assets/historia.jpeg'/>

        <Wrapper>
            <PageHeader>Historia</PageHeader>
            <ContentSection>
                W 2013 roku funkcjonowało około 160 Rad Działalności Pożytku Publicznego
                na terenie całego kraju, co w skali procentowej dawało zaledwie 5,6% jednostek samorządowych, przy
                których
                funkcjonowały rady pożytku. Nie była
                to satysfakcjonująca liczba biorąc pod uwagę ważność RDPP jako ciał doradczych, stanowiących miejsce
                współpracy oraz dialogu organizacji i administracji. Wzmacnianie tych ciał było niezwykle ważne w
                procesie
                budowania tego dialogu.
            </ContentSection>

            <ContentSection>
                Ministerstwo Pracy i Polityki Społecznej ogłosiło konkurs pn. „Wdrożenie standardów
                współpracy jednostek
                samorządu terytorialnego z organizacjami pozarządowymi”.
                W odpowiedzi na ten konkurs 12 organizacji członkowskich Sieci Wspierania Inicjatyw Pozarządowych SPLOT
                realizowało działania wzmacniające RDPP na terenie
                12 województw.
            </ContentSection>

            <ContentSection>
                W czasie realizacji projektów realizatorzy przeprowadzili badania jakości pracy rad pożytku.
                Stowarzyszenie
                KLON/Jawor przygotowało narzędzie – Przewodnik
                do samooceny Rad Działalności Pożytku Publicznego. Przewodnik ten pozwolił
                na przeprowadzenie dogłębnego badania w zakresie m.in. komunikacji wewnętrznej RDPP, sposobu działania,
                czy
                komunikacji z otoczeniem. Badania te, w większości przypadków, były dla członków rad pożytku pierwszą
                okazją
                do chwili refleksji
                i przyjrzenia się pracy ciała, w którym zasiadają, a także koniecznością odpowiedzi
                na pytania związane z ich zaangażowaniem.
            </ContentSection>

            <ContentSection>
                Podczas realizacji projektów odbyło się wiele spotkań przedstawicieli RDPP różnych szczebli,
                w tym również
                wojewódzkich. To podczas tych spotkań członkowie RDPP zaznaczali, że jest potrzeba stałych spotkań,
                podczas
                których przedstawiciele Rad mogliby dyskutować, wymieniać się doświadczeniami, czy też informować
                nawzajem o
                podejmowanych działaniach. Zatem powołanie Konwentu WRDPP było odpowiedzią na konkretną potrzebę
                przedstawicieli tych ciał.
            </ContentSection>

            <ContentSection>
                W pracach nad projektem powołania Konwentu WRDPP uczestniczyli członkowie rad pożytku
                różnych szczebli
                reprezentujący organizacje pozarządowe, samorządy
                oraz radnych gmin, powiatów i sejmików wojewódzkich.
            </ContentSection>

            <ContentSection>
                Konwent Wojewódzkich Rad Działalności Pożytku Publicznego został oficjalnie powołany 27
                listopada 2016 roku
                i obecnie zrzesza szesnaście rad wojewódzkich.
                3 lutego 2017 r., podczas spotkania w Urzędzie Marszałkowskim w Warszawie, wybrano pierwsze prezydium, w
                skład którego weszli: Andrzej Rybus Tołłoczko – przewodniczący (woj. mazowieckie), Joanna Glezman (woj.
                warmińsko- mazurskie), Iwona Hejducka (woj. wielkopolskie), Bożena Lisowska (woj. lubelskie) i Alicja
                Gawinek (woj. opolskie).
            </ContentSection>

            <ContentSection>
                Zgodnie z regulaminem, każda z rad przystępujących do Konwentu deleguje dwójkę swoich
                reprezentantów – po
                jednym ze strony samorządowej i pozarządowej.
                Na posiedzenia zapraszani są reprezentanci krajowej Rady Działalności Pożytku Publicznego oraz dyrekcja
                Narodowego Instytutu Wolności - Centrum Rozwoju Społeczeństwa Obywatelskiego.
            </ContentSection>

            <Images>
                <img src="/assets/1h.jpg" alt="partner1"/>
                <img src="/assets/2h.jpg" alt="partner2"/>
                <img src="/assets/3h.jpg" alt="partner3"/>
                <img src="/assets/4h.jpg" alt="partner4"/>
                <img src="/assets/5h.jpg" alt="partner1"/>
                <img src="/assets/6h.jpg" alt="partner2"/>
                <img src="/assets/7h.jpg" alt="partner3"/>
                <img src="/assets/8h.jpg" alt="partner1"/>
                <img src="/assets/9h.jpg" alt="partner2"/>
                <img src="/assets/10h.jpg" alt="partner3"/>
            </Images>
        </Wrapper>
    </>
);

export default InformacjePage;