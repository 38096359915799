import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faFax, faGlobe, faLocationDot, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
`;

const RowIcon = styled(FontAwesomeIcon)`
  vertical-align: middle;
  color: ${({theme}) => theme.colors.green6};
  height: 20px;
  width: 20px;
`;

const RowText = styled.span`
  margin-left: 1rem;
  vertical-align: middle;
`;

const CenteredName = styled.p`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  line-height: 150%;
  font-weight: bold;
`;

const Row = ({icon, text}) => {
    return (
        <>
            {text && (
                <div>
                    <RowIcon icon={icon}/>
                    <RowText>{text}</RowText>
                </div>
            )}
        </>
    );
}

export const ContactDetails = ({title, children}) => (
    <Wrapper>
        <CenteredName>{title}</CenteredName>
        {children}
    </Wrapper>
);

export const ContactAddress = ({text}) => <Row icon={faLocationDot} text={text}/>;

export const ContactEmail = ({text}) => <Row icon={faEnvelope} text={text}/>;

export const ContactFacebook = ({text}) => <Row icon={faFacebook} text={text}/>;

export const ContactPhone = ({text}) => <Row icon={faPhone} text={text}/>;

export const ContactWww = ({text}) => <Row icon={faGlobe} text={text}/>;

export const ContactName = ({text}) => <Row icon={faUser} text={text}/>;

export const ContactFax = ({text}) => <Row icon={faFax} text={text}/>;

