import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const Image = styled.div`
  background-image: url('${props => props.url}');
  background-clip: border-box;
  height: ${({theme}) => theme.pageImageHeight};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  
  ${down('md')} {
    height: ${({theme}) => theme.mobileImageHeight};
  }
`;

const PageImage = ({url}) => (
    <Image url={url}/>
);

export default PageImage;
