import axios, {AxiosResponse} from "axios";
import {
    Calendar,
    Document,
    MapLocation,
    Multimedia,
    News,
    Office,
    OfficeContact,
    PresidiumMember,
    Rdpp,
    Representative
} from './model';

const BASE_URL = 'https://konwentwrdpp.pl/api/wp-json/wp/v2';

const getObject = <T>(response: AxiosResponse<T>) => response.data;

export const getMapLocations = () =>
    axios.get<MapLocation[]>(`${BASE_URL}/locations?per_page=100`).then(getObject);

export const getArchive = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=12`).then(getObject);

export const getGoodPractices = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=13`).then(getObject);

export const getStrategicDocuments = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=14`).then(getObject);

export const getOurProjects = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=11`).then(getObject);

export const getOpinionsAndExpertises = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=17`).then(getObject);

export const getEPublications = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=16`).then(getObject);

export const getPublicTaskRecomendations = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=15`).then(getObject);

export const getResolutionsAndRecomendations = () =>
    axios.get<Document[]>(`${BASE_URL}/documents?document_category=9`).then(getObject);

export const getPresidiumMembers = () =>
    axios.get<PresidiumMember[]>(`${BASE_URL}/presidium?orderby=menu_order&order=asc&per_page=100`).then(getObject);

export const getNews = () =>
    axios.get<News[]>(`${BASE_URL}/news`).then(getObject);

export const getANews = (id) =>
    axios.get<News>(`${BASE_URL}/news/${id}`).then(getObject);

export const getRdpp = (id) =>
    axios.get<Rdpp>(`${BASE_URL}/rdpp/${id}`).then(getObject);

export const getMarshalDepartments = () =>
    axios.get<Office[]>(`${BASE_URL}/office?offices=21&per_page=100`).then(getObject);

export const getDepartmentsAssistants = () =>
    axios.get<Office[]>(`${BASE_URL}/office?offices=20&per_page=100`).then(getObject);

export const getOfficeContact = () =>
    axios.get<OfficeContact>(`${BASE_URL}/contact/924`).then(getObject);

export const getPodcasts = () =>
    axios.get<Multimedia[]>(`${BASE_URL}/multimedia?multimedia_type=18`).then(getObject);

export const getVideos = () =>
    axios.get<Multimedia[]>(`${BASE_URL}/multimedia?multimedia_type=19`).then(getObject);

export const getCalendars = () =>
    axios.get<Calendar[]>(`${BASE_URL}/calendar`).then(getObject);

export const getRepresentatives = () =>
    axios.get<Representative[]>(`${BASE_URL}/representatives`).then(getObject);

export const getSearch = query =>
    axios.get(`${BASE_URL}/search?subtype[]=documents&subtype[]=multimedia&subtype[]=news&type=post&_embed=self&per_page=100&search=${query}`)
        .then(getObject)
        .then(objects => objects?.map(object => object?._embedded?.self?.[0]) ?? []);
