import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from './Home';
import CzasoumilaczPage from './czasoumilacz';
import PrzedstawicielePage from './Przedstawiciele';
import UchwalyPage from './Uchwaly';
import ProjektyPage from './Projekty';
import DobrePraktykiPage from './Dobre-praktyki';
import ArchiwumPage from './Archiwum';
import PrezydiumPage from './Prezydium';
import DokumentySPage from './dokumenty-strategiczne';
import RekomendacjeZPPage from './rekomendacje-zadan-publicznych';
import PublikacjeElektronicznePage from './publickacje-elektroniczne';
import InformacjaOPWBPage from './informacja-o-publikacjach-w-biurze';
import OpiniePage from './opinie-ekspertyzy';
import PodcastyPage from './podcasty';
import WideoPage from './Wideo';
import BiuroPage from './biuro-kontakt';
import defaultTheme from '../styles/theme';
import ThemeContext from '../styles/ThemeContext';
import CoreLayout from '../layout/CoreLayout';
import CookiesPage from './cookies';
import CopyrightsPage from './prawa-autorskie';
import AccessibilityPage from './deklaracja-dostepnosci';
import StatutPage from './statut';
import InformacjePage from './Informacje';
import MapaTeleadresowaPage from "./mapa-teleadresowa";
import NewsPage from './aktualnosci';
import RdppPage from "./rdpp";
import UrzedyMarszalkowskiePage from "./urzedy-marszalkowskie";
import PelnomocnicyWojewodowPage from "./pelnomocnicy-wojewodow";
import NewsletterPage from './newsletter';
import SzukajPage from './szukaj';

const App = () => {
    const [theme, updateTheme] = useState(defaultTheme);

    return (
        <ThemeContext.Provider value={{theme, updateTheme}}>
            <Router>
                <CoreLayout>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/przedstawiciele" element={<PrzedstawicielePage/>}/>
                        <Route path="/uchwaly" element={<UchwalyPage/>}/>
                        <Route path="/projekty" element={<ProjektyPage/>}/>
                        <Route path="/dobre-praktyki" element={<DobrePraktykiPage/>}/>
                        <Route path="/archiwum" element={<ArchiwumPage/>}/>
                        <Route path="/prezydium" element={<PrezydiumPage/>}/>
                        <Route path="/dokumenty-strategiczne" element={<DokumentySPage/>}/>
                        <Route path="/rekomendacje-zadan-publicznych" element={<RekomendacjeZPPage/>}/>
                        <Route path="/publickacje-elektroniczne" element={<PublikacjeElektronicznePage/>}/>
                        <Route path="/opinie-ekspertyzy" element={<OpiniePage/>}/>
                        <Route path="/podcasty" element={<PodcastyPage/>}/>
                        <Route path="/wideo" element={<WideoPage/>}/>
                        <Route path="/informacja-o-publikacjach-w-biurze" element={<InformacjaOPWBPage/>}/>
                        <Route path="/biuro-kontakt" element={<BiuroPage/>}/>
                        <Route path="/czasoumilacz" element={<CzasoumilaczPage/>}/>
                        <Route path="/polityka-prywatnosci-i-cookies" element={<CookiesPage/>}/>
                        <Route path="/prawa-autorskie" element={<CopyrightsPage/>}/>
                        <Route path="/deklaracja-dostepnosci" element={<AccessibilityPage/>}/>
                        <Route path="/statut" element={<StatutPage/>}/>
                        <Route path="/informacje" element={<InformacjePage/>}/>
                        <Route path="/mapa-teleadresowa" element={<MapaTeleadresowaPage/>}/>
                        <Route path="/aktualnosci/:urlTitle" element={<NewsPage/>}/>
                        <Route path="/rdpp/:name" element={<RdppPage/>}/>
                        <Route path="/urzedy-marszalkowskie" element={<UrzedyMarszalkowskiePage/>}/>
                        <Route path="/pelnomocnicy-wojewodow" element={<PelnomocnicyWojewodowPage/>}/>
                        <Route path="/newsletter" element={<NewsletterPage/>}/>
                        <Route path="/szukaj" element={<SzukajPage/>}/>
                    </Routes>
                </CoreLayout>
            </Router>
        </ThemeContext.Provider>
    );
}

export default App;
