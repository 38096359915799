import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {centeredSection} from '../../styles/mixins';
import {PageHeader} from '../../styles/cssComponents';
import PageImage from 'components/PageImage';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import {getCalendars} from "../../services/apiService";
import {Calendar} from "../../services/model";
import plLocale from '@fullcalendar/core/locales/pl';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const API_KEY_NAME = 'API';

function getApiKey(calendars: Calendar[]) {
    return calendars.filter(calendar => calendar.acf.id === API_KEY_NAME)?.[0]?.acf?.api_key;
}

function getEventSources(calendars: Calendar[]) {
    return calendars
        .filter(calendar => calendar.acf.id !== API_KEY_NAME)
        .map(calendar => ({googleCalendarId: calendar.acf.id}));
}

const CzasoumilaczPage = () => {
    const [calendars, setCalendars] = useState([] as Calendar[]);

    useEffect(() => {
        getCalendars().then(data => {
            setCalendars(data);
        })
    }, []);

    return (
        <>
            <PageImage url='/assets/czasoumilacz.jpeg'/>
            <Wrapper>
                <PageHeader>Czasoumilacz</PageHeader>
                <FullCalendar
                    navLinks
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    locale={plLocale}
                    plugins={[dayGridPlugin, timeGridPlugin, googleCalendarPlugin]}
                    initialView="dayGridMonth"
                    googleCalendarApiKey={getApiKey(calendars)}
                    eventSources={getEventSources(calendars)}
                />
            </Wrapper>
        </>
    )
};

export default CzasoumilaczPage;