import PageImage from 'components/PageImage';
import React from 'react';
import styled from 'styled-components';
import {PageHeader} from 'styles/cssComponents';
import {centeredSection} from 'styles/mixins';
import {down} from "styled-breakpoints";

const Wrapper = styled.div`
  ${centeredSection()};
  width: 80%;
  margin: 0 auto;
  text-align: justify;

  ${down('md')} {
    width: 100%;
  }
`;

const Content = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  text-align: justify;
`;

const ContentSection = styled.p`
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
`;

const ContentLink = styled.a`
  margin-top: 2rem;
  display: block;
  text-align: center;
`;

const AccessibilityPage = () => (
    <>
        <PageImage url='/assets/deklaracja-dostepnosci.jpeg'/>
        <Wrapper>
            <PageHeader>Deklaracja dostępności</PageHeader>
            <Content>
                <ContentSection>
                    Prosimy o zapoznanie się z Oświadczeniem o dostępności witryn wydawanych przez Konwent WRDPP.
                </ContentSection>

                <ContentSection>
                    „Wytyczne dla dostępności internetowej (WCAG) 2.1” obejmują szeroki wachlarz zaleceń dotyczących
                    zwiększenia dostępności treści internetowych. Wdrożenie tych wytycznych sprawi, że treści będą
                    bardziej dostępne dla szerszego grona osób z niepełnosprawnościami, w tym dla osób niewidomych i
                    słabowidzących, głuchych i niedosłyszących, osób z niepełnosprawnością ruchową, z zaburzeniami mowy,
                    nadwrażliwością na światło, osób z niepełnosprawnościami złożonymi, a także dla niektórych osób
                    mających trudności w uczeniu się i ograniczenia poznawcze; ale nie zaspokoi potrzeb każdego
                    użytkownika z niepełnosprawnością. Niniejsze wytyczne dotyczą dostępności treści internetowych na
                    komputerach stacjonarnych, laptopach, tabletach i urządzeniach przenośnych. Wprowadzenie wytycznych
                    w życie, sprawi również, że treści internetowe będą często bardziej użyteczne dla użytkowników w
                    ogóle.
                </ContentSection>

                <ContentLink href="https://www.w3.org/Translations/WCAG21-pl/#abstract-0">
                    https://www.w3.org/Translations/WCAG21-pl/#abstract-0
                </ContentLink>

                <ContentSection>
                    To jest oficjalne oświadczenie o dostępności (accessibility statement). Data ustalenia tekstu
                    Oświadczenia: 10 sierpnia 2022. Data ostatnio wprowadzonych zmian: 10 sierpnia 2022. Chcemy, aby
                    każdy, kto odwiedza nasz serwis, czuł się serdecznie przyjęty i w pełni usatysfakcjonowany. Zgodność
                    ze standardami. Co w tym celu robimy? Aby zapewnić, że nasz serwis będzie użyteczny i dostępny dla
                    jak najszerszej grupy odwiedzających, stosujemy się do wytycznych Web Content Accessibility
                    Guidelines (WCAG) 2.1, które wyjaśniają, co robić, aby treści internetowe były dostępne dla
                    wszystkich ludzi.
                </ContentSection>

                <ContentSection>
                    Jak realizujemy Wytyczne dla dostępności? Twórcy, redaktorzy i autorzy naszego serwisu dokładają
                    wszelkich starań, aby wszystkie strony spełniały wszystkie wymagania WCAG 2.1 na poziomie AA.
                    Kompatybilność Serwis jest w pełni rozpoznawalny przez programy czytające dla osób niewidomych,
                    takie jak np. Window-Eyes, JAWS czy NVDA. Pełna obsługa serwisu możliwa jest zarówno przy pomocy
                    samej klawiatury, jak i myszki. Wygląd Układ graficzny serwisu jest w całości opisany przez arkusze
                    stylów kaskadowych (CSS). Ale nawet jeżeli Twoja przeglądarka lub urządzenie, którym odczytujesz
                    strony internetowe nie wspiera CSS w ogóle, cała zawartość każdej strony jest nadal łatwa do
                    odczytania. Na stronach serwisu używane są tylko względne rozmiary czcionek kompatybilnych z
                    ustawieniami opcji „rozmiar tekstu” w przeglądarkach graficznych. Odnośniki Teksty odnośników są
                    dobrane w taki sposób, aby w miarę możliwości były zrozumiałe nawet poza kontekstem zdania, w którym
                    występują. Wiele łączy zawiera atrybut title opisujący dany odnośnik bardziej szczegółowo, chyba że
                    tekst łącza sam w sobie już dokładnie opisuje dokument docelowy (tak jak np. tytuł artykułu).
                    Obrazki Wszystkie obrazki i grafiki prezentacyjne zawierają opisy alternatywne. Obrazki czysto
                    dekoracyjne zawierają pusty atrybut alt. Złożone grafiki są opisywane w treści strony, tak aby ich
                    znaczenie było jasne również dla osób, które nie mogą zobaczyć obrazów. Udogodnienia Serwis jest
                    wyposażony w udogodnienia ułatwiające przeglądanie treści przez osoby niedowidzące: przełącznik
                    zmiany rozmiaru czcionki przełączniki zmiany wysokości linii, przełącznik zmiany wielkości odstępów.
                    Zauważone problemy Mimo naszych wysiłków, aby spełnić jak najlepiej spełnić wszystkie wymagania na
                    poziomie AA, może się zdarzyć, że na niektórych stronach pojawią się różne usterki. W szczególności
                    mogą one dotyczyć: podwójnego wyświetlania łącza do Facebooka konwentu. Korzystanie z klawiatury i
                    skrótów klawiaturowych Na wszystkich stronach można się posłużyć klawiszem TAB, aby wywołać menu
                    umożliwiające pominięcie niektórych elementów strony i przeskoczenie do wybranego obszaru (treści
                    głównej, menu, treści pobocznych – dodatkowych). Klawisz TAB umożliwia przemieszczanie się po
                    wszystkich aktywnych elementach strony, a kombinacja klawiszy Shift=TAB przemieszczanie się w
                    odwrotnym kierunku.
                </ContentSection>
            </Content>
        </Wrapper>
    </>
);

export default AccessibilityPage;