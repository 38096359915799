import React, { useEffect, useState } from 'react';
import { getNews } from '../services/apiService';
import { News } from '../services/model';
import styled from 'styled-components';
import { Divider, PageHeader } from '../styles/cssComponents';
import { centeredSection } from '../styles/mixins';
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom";
import { down, up } from 'styled-breakpoints';
import {getUrlTitle} from "../services/newsService";

const NewsCardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  
  ${down('md')} {
    &:nth-child(n+2){
      margin-top: 2rem;
      
      &::after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: ${({theme}) => theme.colors.grey};
        width: 100%;
        
        top: -2rem;
      }
    }  
  }
  
  ${down('md')} {
    padding: 0 2rem;
  }
  
  ${up('md')} {
    grid-column: ${props => props.primary ? 'span 2' : 'auto'};
  }
`;

const Image = styled.img`
  border-radius: 25px;
  margin-bottom: 1rem;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 180px;
`;

const PrimaryImage = styled(Image)`
  ${up('md')} {
    height: 320px;
  }
`;

const SDate = styled.p`
  font-size: 14px;
`;

const Title = styled.p`
  font-size: 20px;
  color: ${({theme}) => theme.colors.blue};
`;

const NewsCard = ({imageSrc, date, title, alt, primary = false, news}) => {
    const dateString = dayjs(date).format('DD.MM.YYYY');

    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/aktualnosci/${getUrlTitle(news)}`);
    }

    return (
        <NewsCardWrapper onClick={handleCardClick} primary={primary}>
            {primary ? <PrimaryImage src={imageSrc} alt={alt}/> : <Image src={imageSrc} alt={alt}/>}
            <SDate>{dateString}</SDate>
            <Title>{title}</Title>
        </NewsCardWrapper>
    )
};

const NewsSectionWrapper = styled.div`
  ${centeredSection()};
  padding-bottom: 3rem;
`;

const GridSection = styled.div`
  display: grid;

  grid-gap: 2rem;
  
  ${up('md')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;


const StyledDivider = styled(Divider)`
  margin: 3rem 0;
`;

const NewsSection = () => {
    const [news, setNews] = useState<News[]>([]);

    useEffect(() => {
        getNews().then(data => {
            setNews(data);
        })
    }, [])

    return (
        <NewsSectionWrapper>
            <PageHeader>Aktualności</PageHeader>

            <GridSection>
                {news.slice(0, 3).map((aNews, i) =>
                    <NewsCard
                        key={aNews.id}
                        imageSrc={aNews.acf.image}
                        primary={i === 0}
                        news={aNews}
                        alt={'alt'}
                        date={aNews.date}
                        title={aNews.title.rendered}/>)}
            </GridSection>

            <StyledDivider/>

            <GridSection>
                {news.slice(3, 7).map(aNews =>
                    <NewsCard
                        key={aNews.id}
                        imageSrc={aNews.acf.image}
                        alt={'alt'}
                        news={aNews}
                        date={aNews.date}
                        title={aNews.title.rendered}/>)}
            </GridSection>
        </NewsSectionWrapper>
    );
};

export default NewsSection;