import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

export const Button = styled.button`
  background: ${({theme}) => theme.colors.green1};
  border-radius: 30px;
  padding: 8px 30px;
  border: none;
  color: ${({theme}) => theme.colors.inverseForeground};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: ${({theme}) => theme.colors.blue};
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({theme}) => theme.colors.grey};
`;

export const baseHeader = (separatorColor: string) => css`
  font-size: 1.5rem;

  ${up('md')} {
    font-size: 2rem;
  }

  text-align: center;
  position: relative;

  margin: 1.5rem 0 60px;

  ::after {
    position: absolute;
    height: 1px;
    width: 80%;
    top: calc(100% + 1.5rem);
    left: 10%;
    background-color: ${separatorColor};
    content: '';
  }
`;

export const PageHeader = styled.h3`
  ${({theme}) => baseHeader(theme.colors.green5)};
`;

export const SecondaryHeader = styled(PageHeader)`
  ${({theme}) => baseHeader(theme.colors.grey)};
`;

export const CenteredText = styled.p`
  max-width: 600px;
  margin: 1rem auto;
  text-align: center;
  line-height: 150%;
`;