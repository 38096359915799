import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { centeredSection, desktopOnly } from '../styles/mixins';
import IconInput from './IconInput';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  ${desktopOnly('flex')};

  height: 35px;
  ${({theme}) => centeredSection(theme.colors.green2)}
  color: ${({theme}) => theme.colors.inverseForeground};
  align-items: center;
  justify-content: space-between;
`;

const searchInputStyle = css`
  padding: .300rem 3.8rem .300rem 1rem;
  background-color: darkgreen;
  border: 0;
  color: white;

  ::placeholder {
    color: white;
  }
`;

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleInput = event => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    navigate(`/szukaj?q=${searchQuery}`);
    setSearchQuery('');
  };

  return (
    <Wrapper>
      <span>Nie możesz znaleźć treści na stronie? Skorzystaj z naszej wyszukiwarki. </span>
      <IconInput placeholder="Szukaj" icon={faSearch} onIconClick={handleSearch} onInput={handleInput}
                 emitClickOnEnter style={searchInputStyle} value={searchQuery} iconColor='white'/>
    </Wrapper>
  );
};

export default SearchBar;