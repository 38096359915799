import React from 'react';
import styled from 'styled-components';
import { centeredSection, desktopOnly, mobileOnly } from '../styles/mixins';
import { Button } from '../styles/cssComponents';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { down, up } from 'styled-breakpoints';

const Wrapper = styled.div`
  padding-top: 2rem;
  ${centeredSection()}
`;

const Image = styled.img`
  margin-bottom: 2rem;
  
  ${down('md')} {
    max-width: 80%;
  }
  
  ${up('md')} {
    margin-bottom: 40px;
    margin-top: 60px;
  }
`;

const Grid = styled.div`
  display: grid;
  
  ${down('md')} {
    row-gap: 2rem;
    justify-items: center;
  }
  
  ${up('md')} {
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem 8rem;
  }
`;

const TopGrid = styled.div`
  ${down('md')} {
    display: grid;
    justify-items: center;
  }
`;

const ButtonWrapper = styled.div`
  ${up('md')} {
    * + * {
      margin-left: 20px;
    }
  }
  
  ${down('md')} {
    display: grid;
    row-gap: 1.5rem;
    justify-items: center;
  }
`;

const MediaIcons = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 1rem;
  font-size: 2.5rem;
  
  padding: 1rem 2rem 0;
  
  ${up('md')} {
    padding: 2rem 4rem 0;
  }
`;

const Icon = styled.a`
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const FacebookIcon = styled(Icon)`
  background-color: #4267B2;
`;

const TwitterIcon = styled(Icon)`
  background-color: #1DA1F2;
`;

const YouTubeIcon = styled(Icon)`
  background-color: #FF0000;
`;

const DesktopFacebook = styled.div`
  ${desktopOnly()}

  ${down('md')} {
    max-width: 80%;
  }

  ${up('md')} {
    margin-top: 60px;
  }
`;

const MobileFacebook = styled.div`
  ${mobileOnly()}

  ${down('md')} {
    max-width: 80%;
  }

  ${up('md')} {
    margin-top: 60px;
  }
`;

const Footer = () => (
    <Wrapper>
        <Grid>
            <TopGrid>
                <Image src="/assets/logo.png" alt="Logo"/>

                <ButtonWrapper>
                    <Link to={'/polityka-prywatnosci-i-cookies'}>
                        <Button>Polityka prywatności i cookies</Button>
                    </Link>

                    <a href='https://ekoskop.bip.gov.pl/' target="_blank" rel="noreferrer">
                      <Button>BIP</Button>
                    </a>

                    <Link to={'/deklaracja-dostepnosci'}>
                        <Button>Deklaracja dostępności</Button>
                    </Link>
                </ButtonWrapper>
            </TopGrid>

            <div>
                <DesktopFacebook className="fb-page"
                     data-href="https://www.facebook.com/konwentwrdpp"
                     data-width="380"
                     data-hide-cover="false"
                     data-show-facepile="true"></DesktopFacebook>

                <MobileFacebook className="fb-page"
                                 data-href="https://www.facebook.com/konwentwrdpp"
                                 data-width="275"
                                 data-hide-cover="false"
                                 data-show-facepile="true"></MobileFacebook>

                <MediaIcons>
                    <FacebookIcon href={'https://www.facebook.com/konwentwrdpp'} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </FacebookIcon>
                    <TwitterIcon href={'https://twitter.com/konwentwrdpp'} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter}/>
                    </TwitterIcon>
                    <YouTubeIcon href={'https://www.youtube.com/watch?v=Q-cGcJZv7Aw'} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube}/>
                    </YouTubeIcon>
                </MediaIcons>
            </div>
        </Grid>
    </Wrapper>
);

export default Footer;