import React from 'react';
import styled from 'styled-components';
import { Button } from '../styles/cssComponents';
import { down } from 'styled-breakpoints';
import { Link } from "react-router-dom";
import {getUrlTitle} from "../services/newsService";
import {News} from "../services/model";
import {ellipsis} from "../styles/mixins";

interface DocCardProps {
  title: string;
  content: string;
  linkUrl: string;
  target: 'internal' | 'external';
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin: 0 8rem;

  ${down('md')} {
    grid-template-columns: auto;
    margin: 0;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  justify-items: center;
  row-gap: 1.5rem;
  padding: 1rem;
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: 1rem;
  text-align: center;
`;

const Title = styled.h3`
  color: ${({theme}) => theme.colors.blue};
  font-size: 24px;
  font-weight: bold;
  ${ellipsis(4, 24)}
`;

const Description = styled.p`
  ${ellipsis(10, 16)}
  max-width: calc(100%);
  overflow: hidden;
  white-space: pre-wrap;
`;

const ExternalLink = styled.a`
  align-self: end;
`;

const InternalLink = styled(Link)`
  align-self: end;
`;

const DocCard = ({title, content, linkUrl, target}: DocCardProps) => (
  <CardWrapper>
    <Title>{title}</Title>
    <Description dangerouslySetInnerHTML={{__html: content}}></Description>
    {target === 'internal' &&
      <InternalLink to={linkUrl}><Button>Link</Button></InternalLink>
    }
    {target === 'external' &&
      <ExternalLink href={linkUrl} target={target} rel="noreferrer"><Button>Link</Button></ExternalLink>
    }

  </CardWrapper>
);

export const DocWrapper = ({children}) => (
  <Wrapper>
    {children}
  </Wrapper>
)

export const DocList = ({items}) => {
  const getTarget = doc => doc.type === 'news' ? 'internal' : 'external';

  const getUrl = doc => {
    if (doc.type === 'news') {
      return `/aktualnosci/${getUrlTitle(doc as News)}`;
    }

    if (doc.acf.attachement_type === 'file') {
      return doc.acf.file;
    }

    return doc.acf.url;
  };

  return (
    <DocWrapper>
      {items?.map(item => (
        <DocCard key={item.id} linkUrl={getUrl(item)} title={item.title.rendered}
                 content={item.acf.description} target={getTarget(item)}/>
      ))}
      {items?.length === 0 && (
        <p>Brak dokumentów w wybranej kategorii</p>
      )}
    </DocWrapper>
  );
};

export default DocList;
