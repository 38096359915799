import React from 'react';
import PageImage from '../../components/PageImage';
import { Button, PageHeader } from '../../styles/cssComponents';
import styled from 'styled-components';
import { centeredSection } from '../../styles/mixins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { down } from 'styled-breakpoints';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
  justify-items: center;
`;

const StyledInput = styled.input`
  width: 350px;
  height: 42px;
  padding: 0 1rem;
  
  ${down('md')} {
    width: 80vw;
  }
`;

const ButtonText = styled.span`
  margin-left: 0.5rem;
`;

const NewsletterPage = () => {

    return (
        <>
            <PageImage url='/assets/powiadamiacz.jpeg'/>
            <Wrapper>
                <PageHeader>Powiadamiacz</PageHeader>

                <Grid>
                    <h3>Wpisz swój adres email:</h3>

                    <StyledInput type="text"/>

                    <Button>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        <ButtonText>Zapisz mnie do powiadamiacza!</ButtonText></Button>
                </Grid>
            </Wrapper>
        </>
    );
}

export default NewsletterPage;