const Voivodeships = {
    'dolnoslaskie': 899,
    'kujawsko-pomorskie': 898,
    'lubelskie': 897,
    'lubuskie': 896,
    'lodzkie': 895,
    'malopolskie': 894,
    'mazowieckie': 874,
    'opolskie': 893,
    'podkarpackie': 900,
    'podlaskie': 901,
    'pomorskie': 902,
    'slaskie': 903,
    'swietokrzyskie': 904,
    'warminsko-mazurskie': 905,
    'wielkopolskie': 906,
    'zachodnio-pomorskie': 907,
};

export default Voivodeships;

export const VoivodeshipTranslations = {
    899: 'Województwo Dolnośląskie',
    898: 'Województwo Kujawsko-Pomorskie',
    897: 'Województwo Lubelskie',
    896: 'Województwo Lubuskie',
    895: 'Województwo Łódzkie',
    894: 'Województwo Małopolskie',
    874: 'Województwo Mazowieckie',
    893: 'Województwo Opolskie',
    900: 'Województwo Podkarpackie',
    901: 'Województwo Podlaskie',
    902: 'Województwo Pomorskie',
    903: 'Województwo Śląskie',
    904: 'Województwo Świętokrzyskie',
    905: 'Województwo Warmińsko-Mazurskie',
    906: 'Województwo Wielkopolskie',
    907: 'Województwo Zachodnio-Pomorskie',
};
