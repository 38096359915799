import React from 'react';
import styled from 'styled-components';
import { CenteredText, PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const TextWithBackground = styled(CenteredText)`
  color: white;
  background-color: blue;
  border-radius: 15px;
  padding: 20px;
`;

const CopyrightsPage = () => (
    <Wrapper>
        <PageHeader>Prawa Autorskie</PageHeader>
        <CenteredText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aperiam dolor dolore, fugiat
            illo in ipsum
            maxime minima nobis numquam odio, praesentium quae, qui quibusdam recusandae sint totam unde! Aliquam
            aliquid asperiores commodi consequuntur cumque delectus dicta dolor eius eligendi et, eum expedita harum
            hic
            in ipsa laudantium molestiae neque nihil nostrum numquam pariatur perspiciatis qui quo recusandae
            repellendus similique tempore ullam! Ab ad adipisci aliquid beatae consectetur dignissimos ducimus ea
            eius
            et ex excepturi explicabo facere illo labore magnam maiores nemo perferendis perspiciatis quae qui quis
            quod
            recusandae similique sint sunt tempora, voluptate voluptatem? Accusantium aspernatur assumenda
            consequuntur
            corporis dolorem dolorum ea error est explicabo facere fugiat hic iste laborum magnam nam nemo nesciunt
            nobis nulla pariatur possimus quaerat quo reiciendis, repudiandae sint suscipit tenetur voluptates. Ad
            aperiam aut commodi cum cupiditate dignissimos distinctio eius eum fuga illo in incidunt ipsam libero
            minus
            odit, possimus praesentium quod repellendus repudiandae tenetur. Amet consequatur, consequuntur cum
            dolor
            eius error excepturi minus nesciunt perspiciatis qui quos rem saepe sequi tempore voluptas. Aliquid
            autem
            culpa dolor eius est excepturi incidunt, inventore ipsam labore maiores praesentium quasi ratione sint
            velit
            voluptatem. Aut delectus distinctio, fugit, hic mollitia, nemo non numquam quod repellat tenetur
            vel?</CenteredText>
        <TextWithBackground>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda beatae distinctio
            excepturi ipsa maiores
            recusandae ullam, voluptate. Architecto asperiores doloremque enim eveniet fuga, fugit hic laborum
            laudantium nemo obcaecati officiis porro quidem ratione recusandae sapiente unde velit veniam veritatis!
            Ab
            blanditiis cumque distinctio eaque, eligendi eveniet excepturi hic nemo nesciunt nostrum officia
            officiis
            praesentium quaerat quia quod saepe sequi soluta. Accusamus beatae eius fugit non obcaecati quia
            quibusdam
            quidem sequi ullam unde. Architecto deleniti eligendi excepturi harum obcaecati quia
            tempora!</TextWithBackground>
        <CenteredText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus fuga illum iure iusto
            laborum natus sequi
            totam ut. Aliquam asperiores corporis illum numquam! A adipisci at atque autem cupiditate, distinctio
            ducimus eaque earum eum, fugiat fugit id laudantium magni molestiae nam necessitatibus, nemo nisi nulla
            odio
            odit pariatur placeat quam quia recusandae reiciendis suscipit tempore temporibus ut vitae voluptas?
            Alias
            commodi cum delectus dicta enim, eos eum fuga ipsum necessitatibus nihil obcaecati odio quis repellendus
            sint soluta suscipit velit vero. Asperiores at atque dignissimos dolore doloremque et eveniet illum
            maxime
            necessitatibus optio pariatur quaerat quas saepe, ut voluptatem. Accusantium blanditiis dignissimos ea
            eum,
            necessitatibus praesentium similique voluptatum? Ad et, hic ipsum non nulla sapiente sequi voluptatem
            voluptatibus! Ab accusantium, aliquam aliquid aperiam consectetur deleniti dolor doloremque dolores
            eaque
            eius eos error esse est eum explicabo fugiat incidunt ipsum libero magnam magni maxime molestiae
            mollitia
            officiis pariatur possimus quia, ratione repellat rerum sapiente, sint. Ad atque distinctio explicabo
            ipsum
            officiis sed tenetur voluptate. Atque corporis culpa, cupiditate dicta ea enim eum exercitationem harum,
            impedit mollitia nam neque placeat quibusdam quod saepe tempore, vero! Aliquid asperiores assumenda
            consequuntur deserunt enim error impedit laborum maiores molestiae necessitatibus nesciunt nisi nobis
            officia optio, saepe?</CenteredText>
    </Wrapper>
);

export default CopyrightsPage;