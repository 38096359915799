import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from 'components/Header';
import GlobalStyles from '../styles/GlobalStyles'
import SearchBar from '../components/SearchBar';
import useTheme from '../styles/useTheme';
import Sponsors from '../components/Sponsors';
import { useLocation } from 'react-router-dom';

const CoreLayout = ({children}) => {
    const { theme } = useTheme();
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Header/>
            <SearchBar/>
            <div>
                {children}
            </div>
            <Sponsors/>
        </ThemeProvider>
    );
};

export default CoreLayout;