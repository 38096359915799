import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { getEPublications } from '../../services/apiService';
import DocList from '../../components/DocList';
import PageImage from 'components/PageImage';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const PublikacjeElektronicznePage = () => {
    const [docs, setDocs] = useState(null);

    useEffect(() => {
        getEPublications().then(data => {
            setDocs(data);
        })
    }, []);

    return (
        <>
            <PageImage url='/assets/publikacje-elektroniczne.jpeg'/>

            <Wrapper>
                <PageHeader>Publikacje elektroniczne</PageHeader>
                <DocList items={docs}/>
            </Wrapper>
        </>
    );
};

export default PublikacjeElektronicznePage;