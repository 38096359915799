import { css } from 'styled-components';
import { up } from 'styled-breakpoints';

export const centeredSection = (bgc: string = null) => css`
  background-color: ${bgc};
  padding-left: 22px;
  padding-right: 22px;
  
  ${up('md')} {
    padding-left: calc((100vw - 1280px) / 2);
    padding-right: calc((100vw - 1280px) / 2);
  }
`;

export const desktopOnly = (display: string = 'block') => css`
  display: none !important;

  ${up('md')} {
    display: ${display} !important;
  }
`;

export const mobileOnly = (display: string = 'block') => css`
  display: ${display} !important;
  
  ${up('md')} {
    display: none !important;
  }
`;

export const ellipsis = (lineCount, fontSize: number = 16) => css `
  max-height: ${({theme}) => theme.lineHeight * lineCount * fontSize}px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${lineCount};
  -webkit-box-orient: vertical;
`;
