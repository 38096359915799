import {centeredSection} from "../../styles/mixins";
import React, {useEffect, useState} from "react";
import {getMarshalDepartments} from "../../services/apiService";
import { CenteredText, PageHeader } from "../../styles/cssComponents";
import styled from "styled-components";
import ContactList from "../../components/ContactList";
import PageImage from "components/PageImage";

const Wrapper = styled.div`
  ${centeredSection()};
`;

const UrzedyMarszalkowskiePage = () => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        getMarshalDepartments().then(data => {
            setDepartments(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/urzedy-marszalkowskie.jpeg'/>
            <Wrapper>
                <PageHeader>Urzędy Marszałkowskie</PageHeader>
                <CenteredText>Adresy i inne informacje kontaktowe do Urzędów Marszałkowskich w Polsce.</CenteredText>
                <ContactList offices={departments}/>
            </Wrapper>
        </>
    )
};

export default UrzedyMarszalkowskiePage;
