import PageImage from 'components/PageImage';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'styles/cssComponents';
import { centeredSection } from 'styles/mixins';
import { MultimediaCard, MultimediaList } from '../../components/Multimedia';
import { getVideos } from '../../services/apiService';
import { Multimedia } from '../../services/model';

const Wrapper = styled.div`
  ${centeredSection()};
`;

const WideoPage = () => {
    const [docs, setDocs] = useState<Multimedia[]>([]);

    useEffect(() => {
        getVideos().then(data => {
            setDocs(data);
        })
    }, [])

    return (
        <>
            <PageImage url='/assets/wideo.jpeg'/>
            <Wrapper>
                <PageHeader>Wideo</PageHeader>
                <MultimediaList>
                    {docs.map(doc => <MultimediaCard key={doc.id} title={doc.title.rendered} description={doc.acf.description} video={doc.acf.url} />)}
                </MultimediaList>
            </Wrapper>
        </>
    );
}

export default WideoPage;